import React, {useState} from 'react'
import {TextField, Typography, MenuItem, IconButton} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import {ACTIONS} from './Bins'


function BinField({idx,  array, obj, types, dispatch }) {

    const tIndex=types.findIndex(x=>x.cat===obj.cat)

    const [servList, setServList] = useState(types[tIndex].serv)

    const handleChange=(e)=>{
        let val=e.target.value 
        switch (e.target.name){
            case 'units':
                dispatch({type:ACTIONS.UPDATE_SERVICES, payload:{...array[idx], units:parseInt(val), id:array[idx].id}})
                break
            case 'bins':
                dispatch({type:ACTIONS.UPDATE_SERVICES, payload:{...array[idx], bins:val, id:array[idx].id}})
                break
            case 'cat':
                dispatch({type:ACTIONS.UPDATE_SERVICES, payload:{...array[idx], cat:val.cat, cat_desc:val.cat_desc, id:array[idx].id}})
                setServList(val.serv)
                break
            default:
                dispatch({type:ACTIONS.UPDATE_SERVICES, payload:{...array[idx], serv:val.service, serv_desc:val.serv_desc, id:array[idx].id}})
        }

    }
    
    return (
    <div className='bin'>
        <Typography  variant="body2" className="span stretch">
            Service#{idx+1}: {obj.cat_desc}
        </Typography>

        <TextField select name='cat' value={array[idx]} 
        onChange={handleChange}
            variant="outlined"  label="Category" 
            InputProps={{ style: { fontSize: 15, height: 25, width: 150 }}} 
            InputLabelProps={{style:{fontSize:15}}}
            SelectProps={{renderValue:selected=>selected.cat}}

            >
             {types.map((type, index)=>
                <MenuItem style={{fontSize:15}} key={index} 
                value={type}>{`${type.cat}-${type.cat_desc}`}
                </MenuItem>
             )}
         </TextField>   
           
         <TextField  select name='serv' value={array[idx]} 
           onChange={handleChange} 
            variant="outlined"  label="Service Type"
            InputProps={{ style: { fontSize: 15, height: 25, width:150 }}} 
            InputLabelProps={{style:{fontSize:15}}} 
            SelectProps={{renderValue:selected=>selected.serv}}
            >
            {servList.map((item, index)=>
                <MenuItem  key={index} style={{fontSize:15}} value={item}>{`${item.service}-${item.serv_desc}`}</MenuItem>
             )}
             
         </TextField>

         <TextField name='units' value={array[idx].units} type='number' label='#services'
             onChange={handleChange}
             InputProps={{ style: { fontSize: 15, height: 25, width:150 }}} 
            InputLabelProps={{style:{fontSize:15}}}
             /> 


         {array[idx].cat==='04'&&
         <TextField  name='bins' value={array[idx].bins} 
            onChange={handleChange} 
            variant="outlined" label="Bin #'s"  
            InputProps={{ style: { fontSize: 15, height: 25, width:150 }}} 
            InputLabelProps={{style:{fontSize:15}}} />}
        <IconButton size='small' className='span' color='error' 
        onClick={()=>dispatch({type:ACTIONS.DELETE_SERVICES, id:array[idx].id})}>
            <DeleteIcon fontSize='small'/>
        </IconButton>
         
    </div>
    )
}

export default BinField
