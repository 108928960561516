import React, {useState, useEffect} from 'react'
import { TextField, MenuItem, Autocomplete} from '@mui/material'
import {baseURL} from 'Constants'



export default function AssetSearch({setPosition}) {
    const [assetArray, setAssetArray] =useState(null);
    const [searchType,setSearchType] =useState('asset_name')
    // const [detail, setDetail]=useState({Asset_Details:"Details"})
    useEffect(()=>{
        fetch (`${baseURL}/amsAssets`)
        .then(response=>response.json())
        .then(result=>{
            setAssetArray(result.features);
        })
        .catch(error=>console.log(error.message))
        
    },[])
    
    const filterOptions=(field)=>{
        let arr=[];
        assetArray.forEach((option) => {
            if(option.properties[field]){
                arr.push(option.properties[field])
            }
        })
        return arr
    }

    
    function LocatePolygon(event, value){
        let type
        if (value){
            const typeStr=event.target.id
            if (typeStr.includes('-')){
                type=typeStr.substr(0,typeStr.indexOf('-'))
            }else{
                type=typeStr
            }
            
            // console.log(typeStr, type)
            const result =assetArray.filter(element=>element.properties[type]===value)
            // console.log(result)
            if (result.length===0){
                alert('Please choose appropriate item')
            }else {
                setPosition(result)
                // setDetail(result)
            }
            
        }
    }


    return (
    <div>
        <TextField  value={searchType} 
        onChange={e=>setSearchType(e.target.value)} 
        select fullWidth variant="outlined" size="small" label="Search Field">
            <MenuItem value='asset_name'>Asset Name</MenuItem>
            <MenuItem value='asset_id'>Asset Id</MenuItem>
            <MenuItem value='pk_asset'>Asset Key</MenuItem>
        </TextField>
      
       {assetArray && 
        <Autocomplete
        // sx={{mt:1}}
        id={searchType}
        freeSolo
        options={[...new Set(filterOptions(searchType))]} 
        getOptionLabel={(option) => {
            if (isNaN(option)){
             return option
             }else{
                return option.toString()
            }}}
        
        // {searchType==='pk_asset'? getOptionLabel={(option) => option.toString()}}
        disablePortal
        onChange={LocatePolygon}
        renderInput={(params) => <TextField {...params} size="small" label={`Search ${searchType}`} />}
        />} 
        {/* <pre className='span'>{JSON.stringify(detail, null,2)}</pre>   */}
    </div>
    )
}
