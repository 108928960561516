import React from 'react';
import './App.css';

import {BrowserRouter, Route, Switch} from 'react-router-dom';
import AMAppLogin from "./AMApp/AMAppLogin";
import PublicMap from './PublicMap/PublicMap';
import TourismMap from './TourismMap/TourismMap'


export default function App() {
    
    return(
    <>
      <BrowserRouter>
        {/* <nav>
          <ul>
            <li><Link to="/PublicMap/Ashcroft">Ashcroft Public Map</Link></li>
            <li><Link to="/AMApp">Asset Management Applicaiton</Link></li>
          </ul>
        </nav> */}
            <Switch>
              <Route path='/PublicMap/:muni'>
                  <PublicMap />
              </Route>
              <Route path='/TourismMap/:muni'>
                  <TourismMap />
              </Route>
              <Route path='/AMApp'>
                 <AMAppLogin />
              </Route>
              set route for museum. Removes need to mark app on database
            </Switch>
        </BrowserRouter>
    </>
    )

}

