import React, {useContext,useEffect, useMemo, useState} from "react";
import { Autocomplete, Alert, Button,  TextField, MenuItem, Typography } from "@mui/material";
import './Edit/Asset.css'
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import {AppContext} from "../AM App"
import {useForm} from "./useForm"
import {baseURL} from 'Constants'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


export default function Cemetery() {
  const {cemeteryMapRef, cemetery}=useContext(AppContext);
  // const date=new Date()
 


  const initProfile=useMemo(()=>({
    asset_group: "cemetery_map",
    objectid: "",
    map_id: cemeteryMapRef,
    pk_list: 'New Profile',
    block: "X",
    row_id: "d",
    plot: "6",
    name: "",
    date_interred: new Date(),
    date_reserved: new Date(),
    date_of_death: new Date(),
    family_plot: '',
    notes: ""
  }),[cemeteryMapRef])

  const [alert, setAlert]=useState({
    message:"",
    severity:""
  })

    const [profileList, setProfileList]=useState([initProfile]) //array of profiles
    
    const [profile,handleChange]=useForm(initProfile)

    const [names, setNames]=useState(['uploading...'])


    useEffect(()=>{
        fetch (`${baseURL}/cemetery/list`)
        .then(response=>response.json())
        .then(result=>{
            setNames(result)
                })
      }, [])

  const updateRef=()=>{
    let arr=[]
    cemetery.forEach(elem=>{
      if (elem.properties.map_id===cemeteryMapRef) {
        arr.push(elem.properties)
      }
    })
    setProfileList(arr)
  }

  useEffect(()=>{
    updateRef()
  },[cemeteryMapRef])


  function handleNameSelection(val){
    //find profile object
    const found = cemetery.find(element => element.properties.name===val);
    //replace profile
    //this is where I need to change the map_id --------------------------------------------------
    if (found){
      // console.log("profile:",profile)
      // console.log("found:", found.properties)
      found.properties.map_id=cemeteryMapRef
      handleChange('replaceAsset', found.properties)
    }
    
  }

    function handleSubmit(){
      // console.log(profile)

      fetch (`${baseURL}/cemetery`,{
        method:'PUT',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(profile)
      })
      .then(response=>response.json())
      .then(result=>{
          console.log(result)
          setAlert(result)
      })
      .catch(error=>{
        // console.log(error)
        setAlert({message:error.message, severity:'error'})
      })
    }




    function handleDelete(){

      fetch (`${baseURL}/cemetery/${profile.pk_list}`,
      {
        method:'DELETE',
       })
      .then(response=>response.json())
      .then(result=>{
          setAlert(result)
      })
      .catch(error=>{
        console.log(error)
        setAlert({message:'fetch error', severity:'error'})
      })
    }

    function handleRefDelete(){

      fetch (`${baseURL}/cemetery/${profile.pk_list}`,
      {
        method:'PUT',
       })
      .then(response=>response.json())
      .then(result=>{
          setAlert(result)
      })
      .catch(error=>{
        console.log(error)
        setAlert({message:'fetch error', severity:'error'})
      })
    }
  return (
    <div>
    {/* <pre className='span'>{JSON.stringify(profile, null,2)}</pre>  */}

    <div className="asset">
      <TextField name='replace' value={profile} onChange={handleChange} 
      select  className='span stretch' variant="outlined" size="small" label="Add New or Modify  Profile"
      // SelectProps={{renderValue:selected=>selected.name}}
      SelectProps={{renderValue:selected=>`#${selected.pk_list}-${selected.name}`}}
      >
      
      {profileList.map((profile, idx)=>(
        <MenuItem key={idx} value={profile}>
          {`#${profile.pk_list}-${profile.name}`}
        </MenuItem>
      ))}
      <MenuItem value={initProfile}>New Profile-New Profile</MenuItem>
      </TextField>

      <Autocomplete className='span' 
      id="autoName"
      //value={profile.name} //Issue here <----------------------------------------------------
      value="Choose Name"
      freeSolo
      fullWidth
      options={names}
      disablePortal
      onInputChange={(event, newValue)=>handleNameSelection(newValue, 'replace')}
      renderInput={(params) => <TextField {...params} size="small" label="Find Profile by Name" />}
    />

      <br/>
      <Typography variant='h6' className='span'>Profile Details</Typography>
      <TextField name='name' className='span stretch' value={profile.name}
      onChange={handleChange} variant="outlined" size="small" label="Name"/>

   
   
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
          label="Date Reserved"
          name='dateReserved'
          value={profile.date_reserved}
          onChange={val=>handleChange(val, 'date_interred')}
          renderInput={(params) => <TextField {...params} />}
      />
      <DatePicker
          label="Date Interred"
          name='dateInterred'
          value={profile.date_interred}
          onChange={val=>handleChange(val, 'date_reserved')}
          renderInput={(params) => <TextField {...params} />}
      />
      <DatePicker
          label="Date of Death"
          name='dateDeath'
          value={profile.date_of_death}
          onChange={val=>handleChange(val, 'date_of_death')}
          renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
    <TextField name='family_plot' value={profile.family_plot??""} onChange={handleChange} 
      variant="outlined" size="small" label="Family Plot" />
     <TextField name='notes' value={profile.notes??""} onChange={handleChange} 
      className='span stretch' variant="outlined" size="small" multiline rows={4} label="Notes" /> 

   <Button className='center'  variant='contained' onClick={handleSubmit} startIcon={<SendIcon />}>Submit Updates</Button>
   <Button  className='center'  variant='contained' color='warning' onClick={handleRefDelete} startIcon={<DeleteIcon />}>Remove MapRef</Button>
   <Button  className='center'  variant='contained' color='error' onClick={handleDelete} startIcon={<DeleteIcon />}>Delete Profile</Button>
   {alert.message!==""&&<Alert className= 'span' onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}

  </div>

  </div>
  )
}
