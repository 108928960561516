import { TextField, Stack, Autocomplete} from '@mui/material'
import React from 'react'



export default function Search({pmbc, setPosition}) {
 
    const filterOptions=(field)=>{
        let arr=[];
        pmbc.forEach((option) => {
            if(option.properties[field]){
                if (option.properties.mflag!==true){ //manual pid assignment to trailer park
                    arr.push(option.properties[field])
                }
            }
        })
        return arr
       
    }
    function LocatePolygon(event, value){
        if (value){
            const typeStr=event.target.id
            const type=typeStr.substr(0,typeStr.indexOf('-')) //first part of id is assigned id
            const result =pmbc.filter(element=>element.properties[type]===value)
            setPosition(result)
        }
    }
 

return ( 
<Stack spacing={2} sx={{ width: 300}}>
  
  <Autocomplete
          id="folio"
          freeSolo
          options={[...new Set(filterOptions('folio'))]}
          disablePortal
          onChange={LocatePolygon}
          renderInput={(params) => <TextField {...params} size="small" label="folio" />}
  />
   <Autocomplete
          id="pid"
          freeSolo
          options={filterOptions('pid')}
          disablePortal
          onChange={LocatePolygon}
          renderInput={(params) => <TextField {...params} size="small" label="pid" />}
  />
  <Autocomplete
      id="address"
      freeSolo
      options={[...new Set(filterOptions('address'))]}
      disablePortal
      onChange={LocatePolygon}
      renderInput={(params) => <TextField {...params} size="small" label="address" />}
  />
   <Autocomplete
      id="planid"
      freeSolo
      options={[...new Set(filterOptions('planid'))]}
      disablePortal
      onChange={LocatePolygon}
      renderInput={(params) => <TextField {...params} size="small" label="planid" />}
  />
  <Autocomplete
      id="pin"
      freeSolo
      options={[...new Set(filterOptions('pin'))]}
      disablePortal
      onChange={LocatePolygon}
      renderInput={(params) => <TextField {...params} size="small" label="pin" />}
  />
   <Autocomplete
      id="plannumber"
      freeSolo
      options={[...new Set(filterOptions('plannumber'))]}
      disablePortal
      onChange={LocatePolygon}
      renderInput={(params) => <TextField {...params} size="small" label="plan" />}
  />
  {/* <Autocomplete
      id="legaldescription"
      freeSolo
      options={filterOptions('legaldescription')}
      disablePortal
      onChange={LocatePolygon}
      renderInput={(params) => <TextField {...params} size="small" label="legal description" />}
  /> */}
</Stack>
)
}


 