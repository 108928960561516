import React, {useState, useContext, useEffect, useMemo} from 'react'
import {Autocomplete, Alert, TextField, Typography, Grid, MenuItem, Button, Slider, Stack,IconButton} from "@mui/material"
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import RoomIcon from '@mui/icons-material/Room';
import {AppContext} from "../AM App"
import {useForm} from "./useForm"
import {baseURL} from 'Constants'


export default function Inspect() {



  const {asset, components, markerGeom, setIcon}=useContext(AppContext);
  const [inspections, setInspections]=useState([]);
  const [inspectionTypes, setInspectionTypes] = useState([])
  const [alert, setAlert]=useState({
    message:"",
    severity:""
  })

  const initInspection= useMemo(()=>({
      pk_inspection:'New Inspection',
      fk_asset: asset.pk_asset,
      condition_assessment:0,
      fk_component: '',
      geom: "",
      inspection_description: "",
      inspection_type:"",
      time: ""
  }),[asset.pk_asset])

  const [inspection, handleChange] = useForm(initInspection)

  useEffect(()=>{
    if (!markerGeom) return
    handleChange(markerGeom,'geom')
  },[markerGeom])



  function handleSubmit() { 
      // console.log(inspection)
      fetch (`${baseURL}/inspection`,{
        method:'PUT',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(inspection)
      })
      .then(response=>response.json())
      .then(result=>{
          // console.log(result)
          setAlert(result)
      })
      .catch(error=>{
        // console.log(error)
        setAlert({message:error.message, severity:'error'})
      })
  }
 

  useEffect (()=>{fetch (`${baseURL}/inspectionTypes`)
  .then(response=>response.json())
  .then(result=>{
      // console.log(result)
      setInspectionTypes(result)
  })
  .catch(error=>console.log(error.message))
},[])


useEffect(()=>{
  if (asset.pk_asset!==''){
  fetch (`${baseURL}/inspection/${asset.pk_asset}`)
  .then(response=>response.json())
  .then(result=>{
      setInspections(result)
  })
  .catch(error=>console.log(error.message))
  }
},[asset.pk_asset])

    return (
  <div>
     {/* <pre className='span'>{JSON.stringify(inspection, null,2)}</pre> */}
     
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12}>
      <IconButton sx={{ml:2}}  size='small' color='secondary' onClick={()=>setIcon(true)}>
              Add/Replace Marker... <RoomIcon />
      </IconButton>
    </Grid>

     <Grid item xs={12}>
       <TextField name='pk_asset' value={asset.pk_asset} 
      variant="outlined" size="small" label="Asset Key" disabled />
    </Grid>

    <Grid item xs={12}>
      <TextField variant="outlined" size="small" label="Select Inspection"
        select
        fullWidth
        name='replace'
        onChange={handleChange}
        value={inspection}
        SelectProps={{renderValue:selected=>selected.pk_inspection}}
      >
      <MenuItem value={initInspection}><em>New Inspection/Maintenance</em></MenuItem>
      {inspections.map((inspection, idx)=>(
        <MenuItem key={idx} value={inspection}>
        {inspection.pk_inspection}
        </MenuItem>
      ))}
      </TextField>
    </Grid>
    <Grid item xs={6}>
      <TextField variant="outlined" size="small" label="Component"
        select
        fullWidth
        name='fk_component'
        onChange={handleChange}
        value={inspection.fk_component}
      >
      <MenuItem value={inspection.fk_component} disabled>Select Component</MenuItem>
      {components.map((component,idx)=>(
        <MenuItem key={idx} value={component.pk_component}>
          {`${component.pk_component}-${component.component_name}`}
        </MenuItem>
      ))}
      </TextField>
    </Grid>
  
    <Grid item xs={6}>
    <Autocomplete
          id="inspection_type"
          freeSolo
          fullWidth
          options={inspectionTypes.sort()}
          value={inspection.inspection_type}
          disablePortal
          onInputChange={(event, newValue)=>handleChange(newValue, 'inspection_type')}
          renderInput={(params) => <TextField {...params} size="small" label="Inspection Type" />}
       />
      
    </Grid>
    <Grid item xs={12}>
      <TextField value={inspection.inspection_description} onChange={handleChange}
      name='inspection_description' variant="outlined" size="small" label="Description" fullWidth multiline />
    </Grid>
    <Grid item xs={12}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography id="input-slider">Condition</Typography>
        <SentimentDissatisfiedIcon />
        <Slider
          valueLabelDisplay="auto"
          name="condition_assessment"
          marks
          step={1}
          label="Condition"
          margin="auto"
          defaultValue={3}
          min={1}
          max={5}
          value={inspection.condition_assessment}
          onChange={handleChange}
        />
        <SentimentSatisfiedOutlinedIcon />
      </Stack>
    </Grid>
    <Button variant="contained" sx={{ mt: 3 }} onClick={handleSubmit}>
    Insert/Update
    </Button>
    <Grid item xs={12} >
    {alert.message!==""&&<Alert onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}
      <hr />
    </Grid>
  </Grid>


  </div>
  )
}

