import React from 'react';
import { GeoJSON, LayersControl } from "react-leaflet";
import L, {Icon} from "leaflet";
import {baseURL} from 'Constants'



// put Icon definitions here
const icnSize=[20,20];
const icnSizeSmall=[15,15];
const icnSizeLarge=[30,30];
const icnSizeSmallest=[10,10];

const icnWashroom=new Icon({iconUrl:"/icons/washroom.png",iconSize:icnSizeSmall});
const icnParking=new Icon({iconUrl:"/icons/parking.png",iconSize:icnSize})
const icnBench=new Icon({iconUrl:"/icons/bench.png",iconSize:icnSizeLarge})
const icnBinocs=new Icon({iconUrl:"/icons/binocs.png",iconSize:icnSize})
const icnPicture=new Icon({iconUrl:"/icons/picture.png",iconSize:icnSize})
const icnMural=new Icon({iconUrl:"/icons/painting.png",iconSize:icnSizeSmall})
const icnDefault=new Icon({iconUrl:"/icons/default.png",iconSize:icnSizeSmall})


export default function Overlay({lyrName,lyr}) {
       
    return(
    
        <LayersControl.Overlay  name={lyrName}>
          <GeoJSON data={lyr} style={returnStyle} pointToLayer={returnMarker} onEachFeature={returnFeature}/>
        </LayersControl.Overlay>

    )
}


// ---------------------------supporting functions---------------

    

function returnMarker(json,latlng){
    var att=json.properties;
    let type=att.type
      return(
         L.marker(latlng, {icon:setIcon(type)})
    )
   }

function returnFeature(json,lyr) {
    let att=json.properties;
    lyr.bindPopup(returnTip(att),{maxHeight:300, className:'customPopup' });
    let popup=lyr.getPopup()




    lyr.on('click', (e)=>{
        if (!popup.clicked){
            popup.clicked=true;
            const getImages=async()=>{
                try {
                   const response=await fetch(`${baseURL}/getList/${att.pk_asset}`)
                   if (!response.ok){
                    return response.text().then(text=>{throw Error(text)})
                 }else{
                     const fileNameArray= await response.json()
                    //  console.log(fileNameArray)
                     const resp=await Promise.all(
                         fileNameArray.map(async obj=>{
                            //  console.log(obj.s3path)
                             const res=await fetch(`${baseURL}/getImage`,{
                                method:'POST',
                                headers:{'Content-Type':'application/json'},
                                body:JSON.stringify(obj)
                              })
                              return res
                         })
                     )
                     let currentContent=popup.getContent()
                     popup.setContent('<div class="blink_me">Downloading high resolution images...please wait</div>'+ currentContent)

                    resp.forEach(async(element) => {
                        const blob= await element.blob()
                        const imageObjectURL=URL.createObjectURL(blob)
                                            
                        popup.setContent(currentContent+`<img src=${imageObjectURL} width=100% 
                        onclick="var win=window.open(''); 
                        const img=document.createElement('img');
                        img.src=this.src;
                        img.width=1000;
                        win.document.body.appendChild(img);
                        "></img>`)
                        currentContent=popup.getContent()
                        
                    });
                 }
                } catch (error) {
                    console.log('in catch',error)
                }
            }
            getImages()

            

            // fetch (`${baseURL}/getImage/${att.pk_asset}`) 
            // .then(response=>{
            //     if (!response.ok){
            //        return response.text().then(text=>{throw Error(text)})
                   
            //     }else{
            //         response.blob()
            //     }
            // })
            // .then(result=>{
            //     const imageObjectURL=URL.createObjectURL(result)
            //     const currentContent=popup.getContent()
            //     popup.setContent(currentContent+`<a href=${imageObjectURL} target='new'> <img src=${imageObjectURL} width=100%></img> </a>`)
            // })
            // .catch(err=>console.log(err))
          }
        })
}
   



       
function returnStyle(json){
    let  styles={color: 'gold', weight:4, fillOpacity:0.1, fillColor:'green', dashArray: '5,15', lineCap:'square',maxWidth:10, wordWrap:'break-word' };
    return styles;      
}   



function setIcon(d) {
    return d === 'Trail Viewpoint' ? icnBinocs:
           d === 'Mosaic'  ? icnPicture:
           d === 'Mural'  ? icnMural :
           d === 'Bench'  ? icnBench :
           d === 'Parking'  ? icnParking :
           d === 'Washroom'  ? icnWashroom:
                icnDefault;
}

function returnTip(att){
    var tip="<strong>"+att.asset_name+"</strong><br>"+att.asset_notes
    return tip;
}  

    // // Select element shorthand 
    // const $ = document.querySelector.bind( document ); 
     
    // // Generate random number within range in steps of 10 
    // const range10 = ( min = 20, max = 50 ) => ( ( Math.random() * ( max - min ) | 0 ) + min ) * 10 | 0; 
     
    // // Show our kitten images here 
    // const kittensDisplay = 'body'; 
     
    // // We can get random kitten images from here 
    // const infiniteKittens = ( width = range10(), height = range10() ) => 'http' + '://placekitten' + '.com/' + width + '/' + height; 



    // // Turn a URL into an IMG element using a Promise chain 
    // const getUrlAsImg = src => fetch( src ) 
    //     .then( r => r.arrayBuffer() ) 
    //     .then( ab => URL.createObjectURL( new Blob( [ ab ], { type: 'image/jpeg' } ) ) ) 
    //     .then( src => { const img = document.createElement( 'img' ); img.src = src; return img; } ) 
    //     .catch( console.error ); 
     
    // // Example usage 
    // getUrlAsImg( infiniteKittens() ).then( img => $( kittensDisplay ).appendChild( img ) ); 


    //     // Turn a URL into an IMG element using async/await 
    //     async function createImgFromUrl( srcUrl ) 
    //     { 
    //         const arrayBuffer = await fetch( srcUrl ).then( resp => resp.arrayBuffer() ); 
    //         const img = document.createElement( 'img' ); 
         
    //         img.src = URL.createObjectURL( new Blob( [ arrayBuffer ], { type: 'image/jpeg' } ) ); 
         
    //         return img; 
    //     } 
         
    //     // Example usage. We don't have top-level await yet, so compensate by wrapping an async IIFE 
    //     (async () => 
    //         createImgFromUrl( infiniteKittens() ).then( img => $( kittensDisplay ).appendChild( img ) ) 
    //     )(); 