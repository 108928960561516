import React, {useContext} from 'react';
import './Sidebar.css';
// import {RiFileSearchLine} from "react-icons/ri"
import {FiHome,  FiSearch } from "react-icons/fi";
import {MdExitToApp} from "react-icons/md";
import {GrInspect} from "react-icons/gr";
import {BiCloudUpload} from 'react-icons/bi'
import {FiEdit} from 'react-icons/fi'
import {GiAutoRepair, GiGraveyard} from 'react-icons/gi'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import {TiTickOutline} from "react-icons/ti"
// import {ImBin} from "react-icons/im"
import {GrWorkshop} from "react-icons/gr"

import Home from "../Home/Home.js";
import Search from '../Search'
import Inspect from "../Inspect"
import Repair from "../Repair"
import Upload from "../Upload"
import Reports from "../Reports/Reports"
import Verify from "../Verify"
import Blank from "../Blank"
import BlankBin from "../BlankBin"
import EditContainer from "../Edit/EditContainer"
import Bins from '../Utilities/Bins';
import {AppContext} from '../../AM App'
import AssetSearch from '../../AssetSearch';
import Cemetery from '../Cemetery'
import TodoList from "../TaskLists/TodoList"
import SearchCemetery from '../../../SharedComponents/SearchCemetery'

const Sidebar=({drawerClickHandler, logout})=>{
    const {asset, cemetery, loginInfo, cemeteryMapRef, folio, setDrawerContent, setTitle, pmbc, setPosition,contentLabel}=useContext(AppContext);
// function isEmpty(obj) { 
//     for (var x in obj) { return false; }
//     return true;
//  }




return (
    <div className="sidebar">
        <FiHome className="flex-item" id="home" onClick={()=>{
            drawerClickHandler('Home')
            setTitle("Home")
            setDrawerContent(<Home />);
            }} />
        {pmbc && <FiSearch size={24} className="flex-item"  onClick={()=>{
            drawerClickHandler('Search Parcels');
            setTitle("Search Parcels")
            setDrawerContent(<Search pmbc={pmbc} setPosition={setPosition}/>);
            }} />}
        {/* <RiFileSearchLine className="flex-item" */}

        <img src='/icons/assetSearch.png' alt='asset search' className="img flex-item"
            onClick={()=>{
            drawerClickHandler('Search Assets');
            setTitle("Search Assets")
            setDrawerContent(<AssetSearch setPosition={setPosition}/>);
            }}
        />
        <GrInspect className="flex-item" onClick={()=>{
            drawerClickHandler('Inspect/Maintain')
            setTitle("Inspect/Maintain")
                if (asset===null){
                    setDrawerContent(<Blank />)
                    contentLabel.val='inspect'
                } else {
                    setDrawerContent(<Inspect />)  
                }
            }} />  
        <GiAutoRepair size={24} className="flex-item" onClick={()=>{
            drawerClickHandler('Repair')
            setTitle("Repair")
            // asset==null?setDrawerContent(<Blank />):setDrawerContent(<Repair />)   
            if (asset===null){
                    setDrawerContent(<Blank />)
                    contentLabel.val='repair'
                } else {
                    setDrawerContent(<Repair />)  
                }        
            }} /> 
        <img src='/icons/garbage-bin.png' alt='asset search' className="img flex-item" onClick={()=>{
            drawerClickHandler('Water/Sewer/Garbage')
            setTitle("Water/Sewer/Garbage")
                if (folio===null){
                    setDrawerContent(<BlankBin />)
                    contentLabel.val='utility'
                } else {
                    setDrawerContent(<Bins />)  
                }
            }} />      
        <FiEdit className="flex-item" onClick={()=>{
            drawerClickHandler('Create/Edit Features')
            setTitle("Create/Edit Features")
                if (asset===null){
                    setDrawerContent(<Blank />)
                    contentLabel.val='edit'
                } else {
                    setDrawerContent(<EditContainer />)  
                }
            }} />
        <BiCloudUpload size={26} className="flex-item" onClick={()=>{
            drawerClickHandler('Upload Files')
            setTitle("Upload Files")
                if (asset===null){
                setDrawerContent(<Blank />)
                contentLabel.val='upload'
                } else {
                    setDrawerContent(<Upload />)  
                }
            }} /> 
        <HiOutlineDocumentReport size={26} className="flex-item" onClick={()=>{
            drawerClickHandler('Reports')
            setTitle("Reports")
            setDrawerContent(<Reports />);
            }} />                 
        {loginInfo.role==='Manager' && <TiTickOutline size={26} className="flex-item" onClick={()=>{
            drawerClickHandler('Verify')
            setTitle("Verify")
            setDrawerContent(<Verify />);
            }} />}

        <GrWorkshop size={26} className="flex-item" onClick={()=>{
            drawerClickHandler('Maintenance Management')
            setTitle("Task Management")
            setDrawerContent(<TodoList flag={true} refKey={null} />);
        }} /> 
        {(loginInfo.role==='Admin' || loginInfo.role==='Manager') && <GiGraveyard size={26} className="flex-item" color='red' onClick={()=>{
            drawerClickHandler('Cemetery')
            setTitle("Cemetery App")
            if (cemeteryMapRef===null){
                    setDrawerContent(<Blank />)
                    contentLabel.val='cemetery'
                } else {
                    setDrawerContent(<Cemetery />);
                }
            }} />}
      {(loginInfo.role==='Admin' || loginInfo.role==='Manager') && cemetery && <img src='/icons/cemSearch.png' alt='cicon' className='flex-item' width='25' height='25' onClick={()=>{
            drawerClickHandler('Search Cemetery')
            setTitle("Search Cemetery")
            setDrawerContent(<SearchCemetery cemetery={cemetery} setPosition={setPosition} />);
            }}/>}

        {/* <div className="spacer"></div> */}
        <MdExitToApp  className="flex-item flex-end" onClick={()=>{
           logout() 
        }} />
    </div>
)
}



export {Sidebar as default};


