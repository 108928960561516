
import React , {useContext, useState,useEffect} from 'react'
import { ResponsiveContainer, ComposedChart, AreaChart, Area, Tooltip, BarChart,Bar, Line, Legend, CartesianGrid, YAxis, XAxis } from "recharts"
import {AppContext} from '../PublicMap'
import {baseURL} from 'Constants'

import MakeTable from './MakeTable'

const ehistory =[
  {
    rpt_year: 2016,
    mobile: 65.7,
    indirect: 18.5,
    stationary: 210
  },
  {
    rpt_year: 2017,
    mobile: 89.2,
    indirect: 18.5,
    stationary: 200
  },
  {
    rpt_year: 2018,
    mobile: 74.1,
    indirect: 16.3,
    stationary: 193.81
  },
  {
    rpt_year: 2019,
    mobile: 60.5,
    indirect: 19.5,
    stationary: 306
  },
]

// function createData(number, item, qty, price) {
//   return { number, item, qty, price };
//  }
   
//  const rows = [
//   createData(1, "Apple", 5, 3 ),
//   createData(2, "Orange", 2, 2 ),
//   createData(3, "Grapes", 3, 1 ),
//   createData(4, "Tomato", 2, 1.6 ),
//   createData(5, "Mango", 1.5, 4 )
//  ];
// console.log(rows)

function EnergyChart() {
  const [chartData, setChartData]=useState(false)
  const [eType, setEType]=useState(null)
  const {asset}=useContext(AppContext)
  // console.log(asset)
  useEffect(()=>{
    fetch (`${baseURL}/eTypeChart`)
    .then(response=>response.json())
    .then(result=>{
        // console.log(result)
        setEType([...ehistory, ...result])
        // console.log(ehistory)
    })
    .catch(error=>console.log(error.message))
  }, [])

   useEffect(()=>{
    asset && fetch (`${baseURL}/chart/${asset.pk_asset}`)
    .then(response=>response.json())
    .then(result=>{
        // console.log(result)
        setChartData(result)
    })
    .catch(error=>console.log(error.message))
  }, [asset])
  
    return (
     <>
    <h3>Asset Specific Emmissions tCO2e...Select Asset</h3>
    {chartData[0] && <ResponsiveContainer width="86%" height={250} >
    <ComposedChart data={chartData}>
        <YAxis  yAxisId="left-axis"/>
        <YAxis  yAxisId="right-axis" orientation="right" />
        <XAxis dataKey="rpt_year" />
        <Tooltip/>
        <Legend/>
        <Bar dataKey="tco2e" fill="#eeca9d" yAxisId="left-axis"/>
        <Area type="monotone" dataKey="bio_co2" stroke="#8884d8" fill="#8884d8" yAxisId="right-axis"/>
        <Area type="monotone" dataKey="ch4" stroke="#ff7300" fill="#ff7300" yAxisId="right-axis"/>
        <Area type="monotone" dataKey="n2o" stroke="#82ca9d" fill="#82ca9d" yAxisId="right-axis"/>
    </ComposedChart>
    </ResponsiveContainer>}
    {chartData[0] && <MakeTable rows={chartData}/>}

    <h3>Total Greenhouse Gas Emmissions tCO2e</h3>
    {eType && <ResponsiveContainer width="86%" height={250} >
      <BarChart data={eType} >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis />
          <XAxis dataKey="rpt_year" />
          <Tooltip/>
          <Legend/>
          <Bar dataKey="stationary" stackId="a" fill="#eeca9d" />
          <Bar dataKey="mobile" stackId="a" fill="#8884d8" />
          <Bar dataKey="indirect" stackId="a" fill="#82ca9d" />
        </BarChart>
    </ResponsiveContainer>}
    {eType && <MakeTable rows={eType} />}
   
    </>
    )
}

export default EnergyChart
