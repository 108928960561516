import React, {useContext}  from 'react'
import { Modal, Box, Typography} from '@mui/material'
import MakeTable from './MakeTable';
import {AppContext} from "../AM App"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    height:'75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow:'scroll',
  };


export default function CustomModal() {

    const {setOpenModal, openModal, table, modalTitle}=useContext(AppContext);
    return (
    <div>
      <Modal
        open={openModal}
        onClose={()=>setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalTitle}
          </Typography>
          {table && table.length>0 &&<MakeTable  rows={table}/>}
        </Box>
      </Modal>
    </div>
    )
}
