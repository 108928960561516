import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import {useParams} from "react-router-dom";
import {baseURL} from 'Constants'

import "./TourismMap.css"
import Overlay from "./Overlay"



export default function  TourismMap(){
    const [map, setMap] =useState(null);
    const [json, setJson] =useState({
        DowntownMosaic:[],
        NorthAshcroftMosaic:[],
        ChineseCemetery:[],
        InukshukTrail:[],
        ThreeMeadowsTrail:[],
        AshcroftToAIB:[],
        GovStreetDesertWalk:[],
        GovStElephantMountain:[],
        MesaToBarnsLake:[],
        RiverGardenTrail:[],
        Hyw97CToBonaparteRiver:[],
        Hyw97CNorthLoop:[],
        Hyw97CSouthLoop:[],
        BarnesLakeLoop:[]
        

    });
    const {muni}=useParams();
    var database, mapCenter;
    
    switch (muni) {
        case 'Ashcroft':
            database='ams'; mapCenter=[50.72, -121.28];
            break;
        case'CacheCreek':
            database='ams'; mapCenter=[50.72, -121.28];
            break;
        // eslint-disable-next-line no-throw-literal
        default: throw ('Municipality not chosen');
    }
    
   // Download data using call
    useEffect( function(){
        fetch (`${baseURL}/tourismMap/${database}`)
        .then(response=>response.json())
        .then(result=>{
            // console.log(result)
            let allGeoJson=result.features;
            setJson({
                DowntownMosaic:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Downtown Mosaic Trail'),                
                NorthAshcroftMosaic:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='North Ashcroft Mosaic Trail'),
                ChineseCemetryMosaic:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Chinese Cemetery'),
                InukshukTrail:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Inukshuk'),
                ThreeMedowsTrail:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='3Meadows'),
                AshcroftToAIB:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Ashcroft to AIB'),
                GovStreetDesertWalk:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Govt Desert Walk'),
                GovStElephantMountain:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Govt Elephant Hill'),
                MesaToBarnsLake:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Mesa to Barns Lake'),
                RiverGardenTrail:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='River & Garden Trail'),
                Hyw97CToBonaparteRiver:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='97C to Bonaparte River'),
                Hyw97CNorthLoop:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='97C North Loop'),
                Hyw97CSouthLoop:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='97C South Loop'),
                // BlackCanyon:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Black Canyon'),
                BarnesLakeLoop:allGeoJson.filter(feature=>feature.properties.tourism_grouping==='Barnes Lake Loop'),

            })
        })
        .catch(error=>console.log(error.message))
    },[database])

    map && map.on('overlayadd', (e)=>{
            map.fitBounds(e.layer.getBounds())
    })

    return(
    <div>
        {/* <img src="http://localhost:5000/api/image/08876c58f6e52059c5906ef8064e1501_Rologear AYJackson Ashcroft 1948.jpg" width="300" height= "300" alt='tst'></img> */}

    <MapContainer center={mapCenter} zoom={15} maxZoom={18} zoomControl={false}
    whenCreated={map=>setMap(map)}
    >
        <TileLayer
            url='https://api.Mapbox.com/styles/v1/ysbhalla/ckoqe52rx39j517p5scfqqbiy/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieXNiaGFsbGEiLCJhIjoiY2tvcWN2ZjF0MHB1ZTJ3cnhiN29sZnRrNCJ9.2Gt7VerhEbx1dmhxyurcOQ'
            />  
        <LayersControl position="topright">
            {/* <LayersControl.BaseLayer checked name="Open Street Map">
                <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetMap.org/{z}/{x}/{y}.png"
                />
            </LayersControl.BaseLayer> */}
            {/* <LayersControl.BaseLayer name="MapBox Ortho">
                    
            </LayersControl.BaseLayer> */}
            {json.DowntownMosaic.length>0&&Object.keys(json).map((keyName, index)=>
                <Overlay
                    key={index}
                    lyrName={keyName}
                    lyr={json[keyName]}
                />
            )}
        </LayersControl>
</MapContainer>


    </div>
       

 );
}


