import React from "react";
import {TextField, MenuItem } from "@mui/material";
import "./Asset.css";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';


export default function Financials({handleChange, component}) {
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                      label="Install Date"
                      name='install_date'
                      value={component.install_date}
                      onChange={val=>handleChange(val, 'install_date')}
                      renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                      label="Disposed Date"
                      name='disposed_date'
                      value={component.disposed_date}
                      onChange={val=>handleChange(val, 'disposed_date')}
                      renderInput={(params) => <TextField {...params} />}
                  />
      </LocalizationProvider>
      
      <TextField name='cost' value={component.cost} onChange={handleChange} error={isNaN(component.cost)} variant="outlined" type='number' size="small" label="Cost" />
      <TextField name='amortized_over' value={component.amortized_over} onChange={handleChange} variant="outlined" type='number' size="small" label="Amortized Over" />
      <TextField name='useful_life' value={component.useful_life} onChange={handleChange} variant="outlined" type='number' size="small" label="Userful Life" />
      <TextField name='revised_useful_life' value={component.revised_useful_life} onChange={handleChange} variant="outlined" type='number' size="small" label="Revised Useful Life" />
      <TextField name='crc' value={component.crc} onChange={handleChange} variant="outlined" type='number' size="small" label="CRC" />
      <TextField name='pod' value={component.pod} onChange={handleChange} variant="outlined" type='number' size="small" label="POD" />
      <TextField variant="outlined" size="small" label="Reserve Funding Source" select name='funding_source1' fullWidth value={component.funding_source1} onChange={handleChange}>
        <MenuItem value=""><em>None</em></MenuItem>
        <MenuItem value="Fire RF">Fire Reserve Fund</MenuItem>
        <MenuItem value="Sewer RF">Sewer Reserve Fund</MenuItem>
        <MenuItem value="Water RF">Water Reserve Fund</MenuItem>
        <MenuItem value="M&E RF">Machine & Equipment RF</MenuItem>
        <MenuItem value="Road RF">Road Reserve Fund</MenuItem>
        <MenuItem value="Cemetery RF">Cemetery Reserve Fund</MenuItem>
      </TextField>
    </>
  );
}
