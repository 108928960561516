import React, { useState, useEffect, useMemo, createContext } from 'react';
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import {useParams} from "react-router-dom";
import {SideDrawer} from "./Components/SideDrawer/SideDrawer"
import Sidebar from './Components/Sidebar/Sidebar';
import {MeasureControl} from "../SharedComponents/MeasureControl"
import {baseURL} from 'Constants'

import "./PublicMap.css"
import Overlay from "./Components/Overlay"

import L from 'leaflet';

export const AppContext= createContext(null);
let tempLayer;

export default function  PublicMap(){
    const [sideDrawerShow, setSideDrawerShow] = useState(false);
    const [drawerContent, setDrawerContent]=useState(null);
    const [title, setTitle] = useState(null)
    const [map, setMap] =useState(null);
    const [position, setPosition]=useState(null)      
    const [asset, setAsset]=useState(null)

    const [json, setJson] =useState({
        Wastewater:[],
        Stormwater:[],
        Potablewater:[],
        Fleet:[],
        'Parks, Recreation & Culture':[],
        'Buildings & Structures':[],
        ParcelMap:[],
        Zoning:[]      
    });
    const {muni}=useParams();
    var database, image_dir, mapCenter;
    var attrib='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a><br>'
    attrib+='Powered by <a href="https://ltsa.ca/products-services/parcelmap-bc">ParcelMap BC</a>'

    switch (muni) {
        case 'Ashcroft':
            database='ams'; image_dir='voa/ams';mapCenter=[50.72, -121.28];
            break;
        case'CacheCreek':
            database='ams'; image_dir='CacheCreek/ams';mapCenter=[50.72, -121.28];
            break;
        // eslint-disable-next-line no-throw-literal
        default: throw ('Municipality not chosen');
    }
    
    //Download data using call
    useEffect( function(){
        fetch (`${baseURL}/publicMap/${database}`)
        .then(response=>response.json())
        .then(result=>{
            // console.log(result)
            let allGeoJson=result.features;
            setJson({
                utilityLyr:allGeoJson.filter(feature=>feature.properties.asset_group==='Utility'),
                'Buildings & Structures':allGeoJson.filter(feature=>feature.properties.asset_group==='Buildings & Structures'),
                Fleet:allGeoJson.filter(feature=>feature.properties.asset_group==='Fleet'),
                Wastewater:allGeoJson.filter(feature=>feature.properties.asset_group==='Wastewater'),                
                Potablewater:allGeoJson.filter(feature=>feature.properties.asset_group==='Potablewater'),
                TreeInventory:allGeoJson.filter(feature=>feature.properties.asset_type==='Tree Inventory'),
                Roads:allGeoJson.filter(feature=>feature.properties.asset_subgroup==='Roads'),
                ParcelMap:allGeoJson.filter(feature=>feature.properties.asset_group==='pmbc'),
                Zoning:allGeoJson.filter(feature=>feature.properties.asset_group==='Zoning'),
                Cemetery:allGeoJson.filter(feature=>feature.properties.asset_group==='cemetery_map'),
                Land:allGeoJson.filter(feature=>feature.properties.fs_class==='Land')
            })
            
        })
        .catch(error=>console.log(error.message))
    },[database])

    const providerValues= useMemo(()=>({
        sideDrawerShow, setSideDrawerShow, drawerContent, setDrawerContent,
        title, setTitle, pmbc:json.ParcelMap, cemetery:json.Cemetery, setPosition, asset, setAsset
        }), [sideDrawerShow, drawerContent, title, json.ParcelMap, json.Cemetery, asset])

    const drawerClickHandler=(newTitle)=>{
        //  console.log(`newTitle: ${newTitle}, title: ${title}, sideDrawerShow: ${sideDrawerShow}`)
        if (!sideDrawerShow) setSideDrawerShow(true)
        if (newTitle!==title && sideDrawerShow) return
        if (newTitle===title && sideDrawerShow) setSideDrawerShow(false)
    }

    useEffect(()=>{
        if (position) {
            tempLayer && map.removeLayer(tempLayer);
            tempLayer=L.geoJSON(position)
            map.fitBounds(tempLayer.getBounds(),{maxZoom:21})
            tempLayer.addTo(map).bringToBack();
        }
    },[position, map])

   
  
    map && map.on('overlayadd', (e)=>{
        if(e.name==='Cemetery'){
            map.fitBounds(e.layer.getBounds())
        }
    })

    // console.log(json.Fleet ) 
    return(   
   
<div className="grid-container">
<AppContext.Provider value={providerValues} >
    <Sidebar drawerClickHandler={drawerClickHandler} />
    <SideDrawer />
    <MapContainer center={mapCenter} zoom={15} maxZoom={21} zoomControl={false}
        whenCreated={map=>setMap(map)}
     >
     <MeasureControl />   
     {/* {position && <GeoJSON data={position} style={pathOptions} /> }  */}
   
    <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Open Street Map">
            <TileLayer
            attribution={attrib}
            url="https://{s}.tile.openstreetMap.org/{z}/{x}/{y}.png"
            />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="MapBox Ortho">
            <TileLayer
            url='https://api.Mapbox.com/styles/v1/ysbhalla/ckoqe52rx39j517p5scfqqbiy/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieXNiaGFsbGEiLCJhIjoiY2tvcWN2ZjF0MHB1ZTJ3cnhiN29sZnRrNCJ9.2Gt7VerhEbx1dmhxyurcOQ'
            />            
        </LayersControl.BaseLayer>

        {/* Setting up overlays  */}

        {json.Wastewater.length>0&&Object.keys(json).map((keyName, index)=>
            keyName!=='utilityLyr' && // not mapping utilityLyr which is without geometry 
                <Overlay
                    key={index}
                    lyrName={keyName}
                    lyr={json[keyName]}
                    utilityLyr={keyName==='ParcelMap'? json.utilityLyr :null} //sending data only once
                />
                    
        )}
    </LayersControl>
    </MapContainer>
</AppContext.Provider>
</div> 
 );
}


