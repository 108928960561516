import React, {useContext} from 'react';
import { GeoJSON, LayersControl } from "react-leaflet";
import L, { Icon} from "leaflet";
import {AppContext} from '../PublicMap'
import MarkerClusterGroup from "react-leaflet-markercluster";

let utilityTipObj={};
let assoArray={}
let utilityLayer=[];

// put Icon definitions here
const icnSize=[20,20];
const icnSizeSmall=[15,15];
const icnSizeLarge=[30,30];
const icnSizeSmallest=[10,10];

const icnHydrant=new Icon({iconUrl:"/icons/hydrant.png",iconSize:icnSizeSmall});
const icnAirRelease=new Icon({iconUrl:"/icons/air-release-valve.png",iconSize:icnSize})
const icnBlowoff=new Icon({iconUrl:"/icons/blowoff.png",iconSize:icnSize})
const icnCtlValve=new Icon({iconUrl:"/icons/control-valve.png",iconSize:icnSize})
const icnDischarge=new Icon({iconUrl:"/icons/discharge.png",iconSize:icnSize})
const icnManhole=new Icon({iconUrl:"/icons/manhole.png",iconSize:icnSizeSmall})
const icnPrv=new Icon({iconUrl:"/icons/prv.png",iconSize:icnSize})
const icnPumpStation=new Icon({iconUrl:"/icons/pump-station.png",iconSize:icnSizeLarge})
const icnReservoir=new Icon({iconUrl:"/icons/reservoir.png",iconSize:icnSize})
const icnTank=new Icon({iconUrl:"/icons/tank.png",iconSize:icnSize})
const icnValve=new Icon({iconUrl:"/icons/valve.png",iconSize:icnSizeSmallest})
const icnCleanout=new Icon({iconUrl:"/icons/cleanout.png",iconSize:icnSize})
const icnDefault=new Icon({iconUrl:"/icons/default.png",iconSize:icnSizeSmall})
const icnTree=new Icon({iconUrl:"/icons/treeGreen.png",iconSize:icnSize})
const icnTreeRed=new Icon({iconUrl:"/icons/treeRed.png",iconSize:icnSize})
const icnTreeYellow=new Icon({iconUrl:"/icons/treeYellow.png",iconSize:icnSize})
const icnWarehouse=new Icon({iconUrl:"/icons/warehouse.png",iconSize:icnSize})

const icnCar= new Icon({iconUrl:"/icons/car.png",iconSize:[25,25]})
const icnGarbageTruck=new Icon({iconUrl:"/icons/garbage-truck.png",iconSize:icnSizeLarge})
const icnBackhoe=new Icon({iconUrl:"/icons/backhoe.png",iconSize:icnSizeLarge})
const icnDumpTruck=new Icon({iconUrl:"/icons/dump-truck.png",iconSize:icnSizeLarge})
const icnGrader=new Icon({iconUrl:"/icons/grader.png",iconSize:icnSizeLarge})
const icnLoader=new Icon({iconUrl:"/icons/loader.png",iconSize:icnSizeLarge})
const icnTractor=new Icon({iconUrl:"/icons/tractor.png",iconSize:icnSizeLarge})
const icnStreetSweeper=new Icon({iconUrl:"/icons/street-sweeper.png",iconSize:icnSizeLarge})
const icnTruck=new Icon({iconUrl:"/icons/truck.png",iconSize:icnSizeLarge})
const icnTrailer= new Icon({iconUrl:"/icons/trailer.png",iconSize:icnSizeLarge})
const icnRescue=new Icon({iconUrl:"/icons/rescue.png",iconSize:icnSizeLarge})
const icnFireTruck=new Icon({iconUrl:"/icons/fire-truck.png",iconSize:icnSizeLarge})
const icnTanker=new Icon({iconUrl:"/icons/tanker.png",iconSize:icnSizeLarge})
const icnStreetLight=new Icon({iconUrl:"/icons/street-light.png",iconSize:icnSizeLarge})




export default function Overlay({lyrName,lyr, utilityLyr}) {
   const {setAsset}=useContext(AppContext)
   

   utilityLayer=utilityLyr;
    return(     
        <LayersControl.Overlay  name={lyrName}>
           {lyrName==='Fleet' || lyrName==='Equipment'? ( //for marker clusters
             <MarkerClusterGroup>
                <GeoJSON data={lyr} style={returnStyle} pointToLayer={returnMarker} onEachFeature={returnFeature}/>
              </MarkerClusterGroup>):(
            <GeoJSON data={lyr} style={returnStyle} pointToLayer={returnMarker} onEachFeature={returnFeature}/>
            )}
        </LayersControl.Overlay>
    )


    
    function returnFeature(json,lyr) {
        const assetArray=['Potablewater', 'Stormwater', 'Wastewater', 'Parks, Recreation & Culture', 'Transportation', 'Equipment', 'Fleet', 'Land', 'Buildings & Structures']
        let att=json.properties;
        lyr.bindPopup(returnTip(att),{maxHeight:300, className:'customPopup' });
        if (att.asset_subgroup==='Roads'){
            lyr.bindTooltip(`${att.asset_name} ${att.asset_subtype}`)
        }else{
            lyr.bindTooltip(att.asset_name)
        }
            
        // lyr.on('click', (e)=>console.log(e.layer));
        if (assetArray.includes(att.asset_group)){
            lyr.on('click', function(){
                setAsset(att);
            })
        }
    }
}   


// supporting functions---------------

function returnStyle(json) {
    var styles;
    var att=json.properties;
    if (att.asset_group==='Zoning'){
        styles={color: getColor(att.asset_group), weight:1, fillOpacity:.75, fillColor:getFill(att.zone), maxWidth:10, wordWrap:'break-word' }
    }else if (att.asset_subgroup==='Roads'){
        styles={color: getColor(att.asset_subtype), weight:4, fillOpacity:.75, maxWidth:10, wordWrap:'break-word' }

    }else {
         styles={color: getColor(att.asset_group), weight:1, fillOpacity:0.1, fillColor:'green', maxWidth:10, wordWrap:'break-word' };
    }
    return styles;      
}          

function returnMarker(json,latlng){
    var att=json.properties;
    let type=att.asset_type
    if (type==='Tree Inventory'){
        switch (att.asset_condition) {
            case 1:
                type='TreeInventoryRed'
                break;
            case 2:
                type='TreeInventoryRed'
                break;
            default: type='TreeInventoryGreen'
                break;
        } 
    }
    
      return(
         L.marker(latlng, {icon:setIcon(type)})
    )
   }



function getColor(d) {
    return  d === 'pmbc'  ? '#ff7f00' :
            d === 'Admin'  ? '#cab2d6' :
            d === 'Wastewater'  ? 'brown' :
            d === 'Potablewater'  ? '#1f78b4' :
            d === 'Land'  ? '#fdbf6f' :
            d === 'Environment & Health'  ? '#ff7f00' :
            d === 'Protection'  ? '#e31a1c' :
            d === 'Transport'  ? '#fb9a99' :
            d === 'Public Works'  ? '#b2df8a' :
            d === 'Recreation & Culture'  ? '#33a02c' :
            d === 'Stormwater'  ? 'navy' :
            d === "10km"? 'slategrey':  
            d === "20km"? 'Gray': 
            d === "30km"? 'Orange':    
            d === "40km"? 'blue':
            d === "50km/30km Truck"? 'GreenYellow':
            d === "50km"? 'Green':
            d === "60km"? 'gold':
                'black';
        
}
function getFill(d) {
    return d === 'VR' ? '#1f78b4' :
            d === 'MH1' ? 'SandyBrown' :
            d === 'A1'  ? 'Aquamarine' :
            d === 'RR1'  ? 'LightGreen' :
            d === 'R1'  ? '#cab2d6' :
            d === 'RM1'  ? 'brown' :
            d === 'C1'  ? 'LightSalmon' :
            d === 'M1'  ? '#fdbf6f' :
            d === 'M2'  ? '#ff7f00' :
            d === 'P1'  ? 'SeaGreen' :
                'Gold';
}

function setIcon(d) {
    return d === 'Hydrant' ? icnHydrant :
           d === 'Manhole' ? icnManhole :
           d === 'SW Manhole' ? icnManhole :
           d === 'Control Valve'  ? icnCtlValve :
           d === 'SW Discharge point'  ? icnDischarge :
           d === 'Wastewater Lift Station'  ? icnPumpStation :
           d === 'Cleanout Fitting'  ? icnCleanout :
           d === 'Water Pump Station'  ? icnPumpStation :
           d === 'Water Reservoir'  ? icnReservoir :
           d === 'Valve'  ? icnValve :
           d === 'Water Tank'  ? icnTank :
           d === 'Blowoff Valve'  ? icnBlowoff :
           d === 'Air Release Valve'  ?icnAirRelease :
           d === 'TreeInventoryGreen' ? icnTree:
           d === 'TreeInventoryYellow' ? icnTreeYellow:
           d === 'TreeInventoryRed' ? icnTreeRed:
           d === 'PRV'  ? icnPrv :
           d=== 'Building'?icnWarehouse:
           d=== 'Trailer'?icnTrailer:
           d=== 'Fire Tender'?icnFireTruck:
           d === 'Fire Tanker'  ? icnTanker :
           d === 'Fire Rescue'  ? icnRescue :
           d === 'Bylaw Vehicle'  ? icnCar :
           d === 'Crew Trucks'  ? icnTruck :
           d === 'Backhoe'  ? icnBackhoe :
           d === 'Dump Truck'  ? icnDumpTruck :
           d === 'Garbage Vehicle'  ? icnGarbageTruck :
           d === 'Grader'  ? icnGrader :
           d === 'Loader'  ? icnLoader :
           d === 'Sweeper'  ? icnStreetSweeper :
           d === 'Tractor'  ? icnTractor :
           d === 'Street Lighting'  ? icnStreetLight :
                  icnDefault;
}


function returnTip(att){
    var tip;
      
    switch (att.asset_group){
        case 'pmbc': 
        tip=`<h4><u>Parcel Information</u></h4>
        <b>Folio:</b>${att.folio}
        <br><b>PID:</b>${att.pid}
        <br><b>PIN:</b>${att.pin}
        <br><b>Planid: </b>${att.planid}
        <br><b>Plan Number: </b>${att.plannumber}
        <br><b>Address: </b>${att.address}
        <br><b>Legal: </b>${att.legaldescription}
        <br><b>Area: </b>${att.area}
        <br><b>Perimeter: </b>${att.peri}
        <br><b>Zoning: </b>${att.zone}
        
        ${returnUtility(att.folio)?"<h4><u>Municipal Services</u></h4>"+returnUtility(att.folio):""}
        <br><a href='https://www.bcassessment.ca/' target='blank'>Property Asessment</a>
        <br><br><a href='https://ashcroftbc.ca/garbage-collection/' target='blank'>Garbage Collection Schedule</a>`;
            if (att.globalid in utilityTipObj){
                utilityTipObj[att.globalid]+=tip;
            }else{
                utilityTipObj[att.globalid]=tip;
            }
            tip=utilityTipObj[att.globalid]; 
        break;
        case 'cemetery_map':
            tip="<h5><strong>#"+att.objectid+"; MapKey:"+att.map_id+"; Block:"+att.block+"; Row:"+att.row_id+"; Plot:"+att.plot+"; <br>Family Plot: "+ (att.family_plot!==null?att.family_plot:"")+"</strong>"
            +"<br>Name: "+att.name+"<br>Date Reserved: "+att.date_reserved+"<br>Date Interred:"+att.date_interred+"<br>Date of Death:"+(att.date_of_death!==null?att.date_of_death:"")+"<br>Notes:"+att.notes+"</h5>";
            if (att.map_id in assoArray){
                assoArray[att.map_id]+=tip;
            }else{
                    assoArray[att.map_id]=tip;
            }
            tip=assoArray[att.map_id];
        break;
        case 'Zoning':
        tip="<p> Zone: "+att.zone+"</p>";
        break;          
        default:
         tip="<p><b>Primary Key#: </b>"+att.pk_asset+"<br><strong>Asset: </strong>"+att.asset_name+"<br><b>Asset Group: </b>"+att.asset_group+
         "<br><b>Asset Subgroup: </b>"+att.asset_subgroup
         +"<br><b>Asset Type: </b>"+att.asset_type+"<br><b>Asset SubType: </b>"+att.asset_subtype
         +"<br><b>Condition: </b>"+att.asset_condition+"<br><strong>Asset Notes:</strong> "+att.asset_notes+"</p>";
        }
    return tip;
}  
function returnUtility(val) {
    let utilityTip="";
    if (val!=null){
        const items=utilityLayer.filter(x=>x.properties.folio===val);
          items.forEach(item => {
            const att=item.properties
            switch(att.acct_type.substring(0,5)){
                case 'SOLID':
                utilityTip+=`${att.acct_type} X ${att.units} $${att.cost} <br>BIN# ${att.bins} <br> <br>`;
                break;
                case 'TA':
                    utilityTip+=att.acct_type+" FOR 2023 $"+att.cost+"<br><br>";
                break;
                default:
                utilityTip+=att.acct_type+" X "+att.units+": $"+att.cost+"<br><br>";
            }    
          });
    return utilityTip;
    } 
}     