import React, {useContext, useState} from 'react'
import { Typography, TextField, Button, Stack, Box, Divider, MenuItem} from '@mui/material'
import './Report.css'
import {FaFileCsv, FaMapMarkedAlt} from 'react-icons/fa'
import {useForm} from '../useForm'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {AppContext} from "../../AM App"
import {baseURL} from 'Constants'

export default function Reports() {
    const {setReport}=useContext(AppContext);
    const startDate=new Date(2019,0,1);
    const currentDate= new Date();
    const year= currentDate.getFullYear()
    const [value, handleChange] = useForm({
        auditYear:year,
        mapStart:startDate,
        mapEnd:currentDate,
        asset_group:'Potablewater',
        map_type:""
    }) 
    const [waterToggle, setWaterToggle] = useState(true)
    const [sewerToggle, setSewerToggle] = useState(true)
    const [repairToggle, setRepairToggle] = useState(true)
    const [inspectToggle, setInspectToggle] = useState(true)

    
    const asset_group=['Potablewater', 'Wastewater','Stromwater','Buildings & Structures','Parks, Recreation & Culture' ]

    const fetchMap=(types)=>{
        
        fetch (`${baseURL}/reports/riskMaps`, {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(types)
        })
      .then(response=>response.json())
      .then(result=>{
         setReport(result)
        //  console.log(result)
          
      })
      .catch(error=>console.log(error.message))
    }

  

    return (
    <div className='flex-container' >
        <Typography variant='h6' >CSV (Excel) Report Downloads</Typography>
        <Box sx={{ p: 2, border: '1px dashed grey' }} >
         <Stack direction='row' spacing={1}>
           <TextField  variant="outlined" size="small" label="Select audit year"
                name='auditYear' value={value.auditYear}
                onChange={handleChange}
                fullWidth select >
            <MenuItem value={year}>{year}</MenuItem>
            <MenuItem value={year-1}>{year-1}</MenuItem>
            <MenuItem value={year-2}>{year-2}</MenuItem>
            </TextField>
         </Stack>
            <Button sx={{mt:1}} variant='outlined' size='small' 
             href={`${baseURL}/amort/${value.auditYear}`}
            // onClick={()=>amortReport(value.auditYear)}
            startIcon={<FaFileCsv/>}>Amortization Shedule</Button>
        </Box>
        <Button variant='outlined' size='small' href={`${baseURL}/inventoryListing`}
        startIcon={<FaFileCsv/>}>Asset & Component Listing</Button>
        <Button variant='outlined' size='small' href={`${baseURL}/repairsListing`}
        startIcon={<FaFileCsv/>}>Repairs Listing</Button>
        <Button variant='outlined' size='small' href={`${baseURL}/inspectionsListing`}
         startIcon={<FaFileCsv/>}>Inspections Listing</Button>

 {/* MAPS          */}
        <Typography variant='h6' >Special Maps</Typography>
        <Button variant='outlined' size='small' onClick={()=>{
            waterToggle? fetchMap(['Water Main']): setReport(null)
            setWaterToggle(!waterToggle)
            
        }} color={waterToggle?'primary':'secondary'} startIcon={<FaMapMarkedAlt/>}>{waterToggle?'Show Water Risk Map':'Hide Water Risk Map'}</Button>
        <Button variant='outlined' size='small' onClick={()=>{
            sewerToggle? fetchMap(['Gravity Main','Force Main']): setReport(null)
            setSewerToggle(!sewerToggle)
            }} color={sewerToggle?'primary':'secondary'} startIcon={<FaMapMarkedAlt/>}>{sewerToggle?'Show Sewer Risk Map':'Hide Sewer Risk Map'}</Button>
        <Divider />

        <Typography variant='h7' >Choose Date Range for Repairs or Inspections</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        name="mapStart"
                        value={value.mapStart}
                        onChange={val=>handleChange(val, 'mapStart')}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="End Date"
                        name="mapEnd"
                        value={value.mapEnd}
                        onChange={val=>handleChange(val, 'mapEnd')}
                        renderInput={(params) => <TextField {...params} />}
                    />
        </LocalizationProvider>
        <TextField variant="outlined" size="small" label="Asset Group"
        select
        fullWidth
        onChange={handleChange}
        name='asset_group'
        value={value.asset_group}
      >
       <MenuItem value={value.asset_group} disabled>Select Component</MenuItem>
      {asset_group.map((group, idx)=>(
        <MenuItem key={idx} value={group}>
        {group}
        </MenuItem>
      ))}
      </TextField>
        <Stack direction='row' spacing={1}>
        <Button variant='outlined' size='small' onClick={()=>{
            value.map_type="Repairs"
            repairToggle? fetchMap(value): setReport(null)
            setRepairToggle(!repairToggle)
            }} color={repairToggle?'primary':'secondary'} startIcon={<FaMapMarkedAlt/>}>{repairToggle?'Show Repairs':
            'Hide Repairs'}</Button>
        <Button variant='outlined' size='small' onClick={()=>{
            value.map_type="Inspection"
            inspectToggle? fetchMap(value): setReport(null)
            setInspectToggle(!inspectToggle)
            }} color={inspectToggle?'primary':'secondary'} startIcon={<FaMapMarkedAlt/>}>{inspectToggle?'Show Inspections':
            'Hide Inspections'}</Button>

        </Stack>
    </div>
    )
}

