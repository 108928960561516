import React, { useReducer, useState, useEffect } from 'react'

import Todo from './Todo'
import {useForm} from "../useForm"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {baseURL} from 'Constants'
import {TextField, MenuItem, Button} from "@mui/material"

export const ACTIONS={
  ADD_TODO:'add-todo',
  TOGGLE_TODO:'toggle-todo',
  DELETE_TODO:'delete-todo',
  MODIFY_TODO:'modify-todo',
  SET_TODO:'set-todo'
}

const reducer =(todos, action)=>{
  const {notes, refKey, department, assigned, due, flag}=action.payload
  const table=flag?'title':'detail'
  switch (action.type){
    case ACTIONS.SET_TODO:
      return action.payload.todo

    case ACTIONS.ADD_TODO:
      const obj={id:Date.now(),refKey:refKey, notes:notes, department: department, due:due, assigned: assigned, complete:false}
      // console.log(obj)
      fetch (`${baseURL}/todoList/${table}`,{
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(obj)
      })
   
      return [...todos, obj]
      
    case ACTIONS.TOGGLE_TODO:
    const tArray=todos.map(t=>{
        if(t.id===action.payload.id){
          const toggleObj={id:t.id, complete:!t.complete}

          fetch (`${baseURL}/todoList/toggle/${table}`,{
            method:'PUT',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(toggleObj)
          })
          return {...t, complete:!t.complete}
        }else return t
      })
     return tArray
    
case ACTIONS.MODIFY_TODO:
     const arr= todos.map(t=>{
        if(t.id===action.payload.id){
          const modobj={...t, notes:notes, department: department, due:due, assigned: assigned }
          fetch (`${baseURL}/todoList/update/${table}/`,{
            method:'PUT',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(modobj)
          })
          return modobj
        }else return t
      })
      return arr

    case ACTIONS.DELETE_TODO:
     
        fetch (`${baseURL}/todolist/${table}/${action.payload.id}`,{
          method:'DELETE'
        })
 
      return todos.filter(todo=>todo.id!==action.payload.id)

    default:
       return todos;
  }
}


function TodoList({flag, refKey}) {
  const [todos, dispatch]= useReducer(reducer, [])
  const date= new Date()
  const init={
   id: '',
   notes: '',
   complete: false,
   department:'',
   assigned:'',
   due:date.toDateString()
 }
  const [todo, handleChange] = useForm(init)
   const [filter,setFilter]=useState('All')
 
 
 const depts=['Admin','Public Works','Bylaw']

//flag is true then allow modal. In modal flag false

  //useEffect get saved list; if refKey then get linked list
    useEffect (()=>{
      if (flag){
        fetch (`${baseURL}/todoList`)
        .then(response=>response.json())
        .then(result=>{
            // console.log(result)
            dispatch({type:ACTIONS.SET_TODO, payload:{todo:result}})
        })
        .catch(error=>console.log(error.message))
      }else{
        fetch (`${baseURL}/todoList/${refKey}`)
        .then(response=>response.json())
        .then(result=>{
            // console.log(result)
            dispatch({type:ACTIONS.SET_TODO, payload:{todo:result}})
        })
        .catch(error=>console.log(error.message))
      }
    
    },[flag,refKey])
    
  const handleSubmit=()=>{
    if(todo.id){
      dispatch({type:ACTIONS.MODIFY_TODO, payload:{id:todo.id, notes:todo.notes, department:todo.department, assigned:todo.assigned, due:todo.due, flag:flag}})
    } else{
      dispatch({type:ACTIONS.ADD_TODO, payload:{notes:todo.notes, refKey:refKey, department: todo.department, assigned:todo.assigned, due: todo.due, flag:flag}})
    }
  
    handleChange('replaceAsset',init)
  }


  const handleFilter=(e)=>{
    setFilter(e.target.value)
 }

  return ( 
    <>
         {flag&&<TextField name='department' margin='normal' value={todo.department} onChange={handleChange} 
          select fullWidth variant="outlined" size="small" label='Select Dept'>
          {depts.map((dept, idx)=>(
            <MenuItem key={idx} value={dept}>
              {dept}
            </MenuItem>
          ))}
        </TextField>}
        <TextField value={todo.notes} name='notes' margin='normal' label='Enter Task/Activity' multiline fullWidth onChange={handleChange}/>
        {!flag&&<TextField value={todo.assigned} sx={{mb:1}} fullWidth name='assigned' label='Assigned To' onChange={handleChange}/>}
        {!flag&&<LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Due Date"
                        name='due'
                        value={todo.due}
                        onChange={val=>handleChange(val.toDateString(), 'due')}
                        renderInput={(params) => <TextField {...params} />}
                    />
          </LocalizationProvider>}
       
        <Button  sx={{ ml:1, mb: 2, mt:1}} size='small' variant='contained' justify-content='right' onClick={handleSubmit}>
          Insert/Update
        </Button> 
        <br/>     
      
      {/* <pre>{JSON.stringify(todos,null,2)} </pre> */}
      {flag&&<TextField name='department' margin='normal' onChange={handleFilter} value={filter}
          select fullWidth variant="outlined" size="small" label='Filter List by Dept'>
           <MenuItem value={'All'}>All</MenuItem>
          {depts.map((dept, idx)=>(
            <MenuItem key={idx} value={dept}>
              {dept}
            </MenuItem>
          ))}
        </TextField>}
      
       
      {filter!=='All' && flag?(todos.filter(t=>t.department===filter).map((t, index)=>{    
        return(
          <Todo key={index} flag={flag} refKey={refKey} idx={index} handleChange={handleChange} todo={t}  dispatch={dispatch} />
        )
      })):(todos.map((t, index)=>{
        return(
          <Todo key={index} flag={flag} refKey={refKey} idx={index} handleChange={handleChange} todo={t}  dispatch={dispatch} />
        )
  }))}
    </>
    
  );
}

export default TodoList;


