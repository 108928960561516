import React, {useState, useEffect} from 'react'

import LoginForm from  './Components/LoginForm/LoginForm';
import  AMApp from './AM App'
import {baseURL} from 'Constants'


function MapApp() {
  const [user, setUser]=useState(()=>({
    loggedIn:null,
  }))
  const [error, setError]=useState("");

  useEffect(()=>{
    fetch (`${baseURL}/login`,{
      credentials:"include",
    })
    .then (result=>result.json())
    .then(data=>{
      setUser(data)
    })
  },[])


  function Login(credentials) {
    verify(credentials).then(jsonData=>{
      // console.log("DataAlone", jsonData)

      if (jsonData?.message){
        setError(jsonData.message[0])
      }else{
        setUser(jsonData);
      }
    });
  }


  function Logout() {
    fetch (`${baseURL}/logout`,{
      credentials:"include",
      method: 'DELETE',
    });
    setUser({loggedIn:false})
   
   }
  
   

    async function verify(credentials) {
      try {
        const response= await fetch (`${baseURL}/login`, {
          credentials:"include",
          method: 'POST',
          headers:{'Content-Type':'application/json'},
          body:JSON.stringify(credentials)
        })
        const jsonData=await response.json();
        // console.log(jsonData)
        return jsonData;
       
      } catch (error) {
         console.error(error.message);
      }
      
    }


    return (
      <div>
        {/* <pre>{JSON.stringify(user, null,2)}</pre> */}
        {user?.loggedIn===null ? "":user?.loggedIn===true?(
          <AMApp loginInfo={user} logout={Logout}/>
        ):(<LoginForm
            Login={Login} error={error}
          />)
        }
      </div>
    );
}
export default MapApp;
 

  