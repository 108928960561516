import React, {useState} from 'react'
import { Box, TextField, Alert, Container, Button, Typography, MenuItem } from "@mui/material";
import {baseURL} from 'Constants'


export default function Register({setRegister}) {
  const [alert, setAlert]=useState({
    message:"",
    severity:""
  })
    
    const handleSubmit = (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const data=new URLSearchParams(formData)
      // let email=data.get('email')
      // console.log(email)
      fetch (`${baseURL}/register`,{
        credentials:"include",
        method:'POST',
        body:data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      .then(response=>response.json())
      .then(result=>{
          console.log(result)
          setAlert(result)
      })
      .catch(error=>{
        console.log(error.message)
        setAlert({message:'fetch error', severity:'error'})
      })
      // email: data.get('email'),

    };
  
    return (
      <Container component="main" maxWidth="xs" sx={{ overflowY: 'auto', maxHeight: '95vh' }}>
      <Typography variant='h5'>Registeration Form</Typography>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 0 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="first_name"
            label="First Name"
            name="first_name"
            autoComplete="first name"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="last_name"
            label="Last Name"
            name="last_name"
            autoComplete="last name"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            name="email"
            autoComplete="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="muni"
            label="Municipality"
            id="muni"
            defaultValue="voa"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="role"
            label="Role"
            id="role"
            defaultValue='Staff'
            select >
            <MenuItem value='Staff'>Finance</MenuItem>
            <MenuItem value='Manager'>Administration</MenuItem>
          </TextField>
         
          <TextField
            margin="normal"
            required
            fullWidth
            name="dept"
            label="Department"
            id="dept"
            defaultValue='Public Works'
            select>
            <MenuItem value='Public Works'>Public Works</MenuItem>
            <MenuItem value='Finance'>Finance</MenuItem>
            <MenuItem value='Admin'>Administration</MenuItem>
          </TextField>
         
            <TextField
            type="Password"
            margin="normal"
            required
            fullWidth
            name="token"
            label="Token"
            id="token"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 1 }}
          >
            Submit Registeration
          </Button>
          <Button
            fullWidth
            variant="outlined" 
            color='secondary'
            sx={{ mt: 1, mb: 2 }}
            onClick={()=>setRegister(false)}
          >
            Return to Logon
          </Button>
        </Box>
        {alert.message!==""&&<Alert className='span stretch' onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}

      </Container>
    );
  }
  