import React, {useState} from 'react'
import {ACTIONS} from './TodoList'
import {IconButton, Typography, Stack} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ModalList from './ModalList'
import "./Todo.css"

export default function Todo({todo, flag, refKey, idx, dispatch, handleChange}) {
    const [openModal, setOpenModal]=useState(false)
return (
    <div className='container' >
    
        <Typography display='inline' onClick={()=>dispatch({type:ACTIONS.TOGGLE_TODO, payload:{id:todo.id, refKey:refKey, flag:flag}})}
            style={{textDecoration:todo.complete?"line-through":""}}
 
             >
          #{idx+1}&nbsp;  
            {todo.notes}
            &nbsp; {flag && todo.department}
            &nbsp; {!flag && todo.assigned}
            &nbsp; {!flag && new Date(todo.due).toDateString()}
        </Typography>
        <Stack direction="row" alignItems="center">
     <IconButton onClick={()=>dispatch({type:ACTIONS.DELETE_TODO, payload:{id:todo.id, refKey:refKey, flag:flag}})}
        color='error' size='small'
        ><DeleteIcon fontSize='small' /></IconButton>

        <IconButton color='primary' size='small' onClick={()=>{
            handleChange('replaceAsset',todo)
        }}><EditIcon/></IconButton>

{ flag && <IconButton color='primary' size='small' onClick={()=>setOpenModal(true)}>
            <ListAltIcon/>
        </IconButton>}


     </Stack>

        {openModal && <ModalList openModal={openModal} setOpenModal={setOpenModal} refKey={todo.id}/>}

    </div>
 )
}

 