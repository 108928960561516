import React from 'react'
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'


 export default function MakeTable({rows}){
  const headers=Object.keys(rows[0]) //select first object and get keys
  return(
    <TableContainer sx={{ width: "100%"}}component={Paper}>
    <Table size="small"  >
      <TableHead>
      <TableRow>
      {headers.map((header,idx)=>(
          <TableCell sx={{fontSize:12}} key={idx}>{header}</TableCell>
      ))}
      </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, idx)=>(
          <TableRow   key={idx}>
          {headers.map((header, index)=>(
          <TableCell  sx={{fontSize:12, }} key={index}>{row[header]}</TableCell>
          ))}
          </TableRow>
        ))}
      </TableBody>  
    </Table>
    </TableContainer>
  )
}

// sx={{ width: "90%", display: "table", tableLayout: "fixed" }}