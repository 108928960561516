import React, {useContext} from 'react'
import  './SideDrawer.css';
import  {Typography }from "@mui/material"
import {AppContext} from '../../AM App'
import {FaChevronLeft} from "react-icons/fa"


export const SideDrawer =()=>{
    const {title,drawerContent, sideDrawerShow, setSideDrawerShow}=useContext(AppContext);

    let drawerClasses='side-drawer'
    if (sideDrawerShow){
        drawerClasses='side-drawer open'
    }
    return (
       <div className={drawerClasses}>
        <SideDrawerContainer title={title} drawerContent={drawerContent} 
                setSideDrawerShow={setSideDrawerShow}
        />
       </div>      
    )
}

const SideDrawerContainer = ({title, drawerContent, setSideDrawerShow})=>{
    
    return(
        <div>
            <div className="titleContainer">
                {/* <Chip icon={<GiGearHammer />} sx={{mb:2, mr:1, color:colour}} label={`Asset#${asset.pk_asset}`} /> */}
                <Typography variant="h6" className="title">{title}</Typography>
                <FaChevronLeft className="chev" onClick={()=>setSideDrawerShow(false)}/>
            </div>
            {drawerContent}
        </div>
    )
}
