import React, {useEffect, useState, useContext} from "react";
import { Button, TextField, MenuItem} from "@mui/material";
import "./Edit/Asset.css";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {AppContext} from "../AM App"
import {useForm} from "./useForm"
import {baseURL} from 'Constants'

const today=new Date();


export default function Verify() {
  const {setReport}=useContext(AppContext);
  const [assetGroup, setAssetGroup]= useState([''])
  const [verify, handleChange]=useForm({
    asset_group:'',
    start_date:new Date(2019,0,1),
    end_date:today,
  })

  useEffect(()=>{
    fetch (`${baseURL}/amsMap/dimensions/`)
    .then(response=>response.json())
    .then(result=>{
        // console.log(result.asset_group)
        setAssetGroup(result.asset_group)   
      })
  },[])
  
  // const handleSubmit = () => {
  //   console.log(verify);
  // };

  const fetchMap=()=>{
    // console.log(verify)
    fetch (`${baseURL}/verify`, {
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(verify)
    })
  .then(response=>response.json())
  .then(result=>{
      // console.log(result)
      setReport(result)
  })
  .catch(error=>console.log(error.message))
}
  return (
    <div className='asset' >
      <LocalizationProvider  style={{marginTop:"5px"}} dateAdapter={AdapterDateFns}>
            <DatePicker 
                label="start_date"
                value={verify.start_date}
                onChange={val=>handleChange(val, 'start_date')}
                renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker 
                label="end_date"
                value={verify.end_date}
                onChange={val=>handleChange(val, 'end_date')}
                renderInput={(params) => <TextField {...params} />}
            />
      </LocalizationProvider>
      <TextField  className='span' variant="outlined" size="small" label="Filter by Asset Group"
            name='asset_group'
            value={verify.asset_group}
            onChange={handleChange}
            select
            fullWidth
          >
          {assetGroup.map((fs, idx)=>(
            <MenuItem key={idx} value={fs}>
              {fs}
            </MenuItem>
          ))}
       </TextField>
       <Button onClick={fetchMap} className='span' sx={{margin:'auto'}} variant='contained'>Filter</Button>     
    </div>
  );
}
