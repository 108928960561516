import React from 'react'
import { Modal, Box, Typography} from '@mui/material'
import TodoList from './TodoList'

import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const style = {
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    overflow:'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


export  function ModalList({openModal, setOpenModal, refKey}) {

   
    return (
    <div>
      <Modal
        open={openModal}
        onClose={()=>setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" sx={{ mt: -2, textDecoration:'underline' }}>
            List of Activities
          </Typography>

        <TodoList flag={!openModal} refKey={refKey}/>
    
        </Box>
      </Modal>
    </div>
    )
}


export default function FormDialog({openModal, setOpenModal, refKey}) {

  
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
   
      <Dialog open={openModal}  onClose={handleClose}>
        <DialogTitle> Activities</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Enter activities that comprise the task.
          </DialogContentText>
          <TodoList flag={!openModal} refKey={refKey}/>
        </DialogContent>
       
      </Dialog>
    </div>
  );
}