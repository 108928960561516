import React, {useContext} from 'react';
import './Sidebar.css';
import { FiHome,  FiSearch } from "react-icons/fi";
import {MdExitToApp} from "react-icons/md";
import Home from "../Home/Home.js";
import Search from '../Search'
import EnergyChart from "../EnergyChart"
import SearchCemetery from '../../../SharedComponents/SearchCemetery'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import {AppContext} from '../../PublicMap'


const Sidebar=({drawerClickHandler, logout})=>{
    const {setDrawerContent, setTitle, pmbc, cemetery, setPosition}=useContext(AppContext);
//    console.log(cemetery)
return (
    <div className="sidebar">
        <FiHome className="flex-item" onClick={()=>{
            drawerClickHandler('Home')
            setTitle("Home")
            setDrawerContent(<Home />);
            }} />
        {pmbc[0] && <FiSearch className="flex-item"  onClick={()=>{
            drawerClickHandler('Parcel Search');
            setTitle("Parcel Search")
            setDrawerContent(<Search pmbc={pmbc} setPosition={setPosition}/>);
            }} />}

       {cemetery && <img src='/icons/cemSearch.png' alt='cicon' className='flex-item' width='25' height='25' onClick={()=>{
            drawerClickHandler('Search Cemetery')
            setTitle("Search Cemetery")
            setDrawerContent(<SearchCemetery cemetery={cemetery} setPosition={setPosition} />);
            }}/>}
        <HiOutlineDocumentReport className='flex-item' onClick={ ()=>{
            drawerClickHandler('Energy Report')
            setTitle("Energy Report")
            setDrawerContent(<EnergyChart/>)
        }} />

        <div className="spacer"></div>

        <MdExitToApp className="flex-item flex-end" />
     
    </div>
)
}




export default Sidebar;
