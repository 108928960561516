import React from 'react'
import  {Typography, Button} from "@mui/material";
import {AiOutlineYoutube} from "react-icons/ai"
import "./Home.css"
export default function Home() {
    
    return (
         <div>
            <Typography variant='h6'>Welcome to the Mapping App!</Typography>
            <Typography variant='body2'>The video below is a introduction on how to use the app.
            The accordian on the right allows you to select layers such as different asset groups such as Wastewater, Potablewater, Fleet and Roads.
            Clicking on the layer allows you to view details. For example, after clicking on a particular parcel in the Parcel layer
            brings up details such as legal and pid and size of the property. The sidebar on the left is composed of icons that
            that have different functions such as the search icon denoted by a magnifying glass which allows you to find any property you're looking for using address, pid or the legal discription.
            As you type, you are shown suggestions that match you input.
            <br/>
            Enjoy!
             </Typography>
            <Button href="https://www.youtube.com/watch?v=h1aOa4UPMIM" target="_blank"><AiOutlineYoutube className="icon"/> Intro Video</Button>
            
        </div>
    )
} 
