import React, {useState} from 'react'
import { TextField, Stack, Autocomplete, MenuItem, Typography, Button} from '@mui/material'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';



const newDate=new Date()



export default function SearchCemetery({cemetery, setPosition}) {
  

    const [range, setRange]=useState({
        type:'date_interred',
        start:newDate,
        end:newDate
    })

    const filterOptions=(field)=>{ //for autiofill
        let arr=[];
        cemetery && cemetery.forEach((option) => {
            if(option.properties[field]){
                if (option.properties.mflag!==true){
                    arr.push(option.properties[field])
                }
            }
        })
        return arr
       
    }
    function LocatePolygon(event, value){
        if (value){
            const typeStr=event.target.id
            const type=typeStr.substr(0,typeStr.indexOf('-'))
            const result =cemetery.filter(element=>element.properties[type]===value)

            if (result.length===0){
                alert('Please choose appropriate item')
            }else {
                setPosition(result)
            }
        }
    }

    const handleChange=(e,type)=>{
        if (e.target) {
            setRange({
                ...range,
                [e.target.name]:e.target.value
            
            })
        }else{
           setRange({
               ...range,
               [type]:e
           }) 
        }
    }

const highlightPlots=()=>{
    const {type, start, end}= range

    const result=cemetery.filter((grave)=>{
        const dt=new Date(grave.properties[type])
       return dt>=start && dt<=end
   })
   result[0] && setPosition(result)
}

return ( 
<Stack spacing={2} sx={{ width: 300}}>
  
  <Autocomplete
          id="name"
          freeSolo
          options={[...new Set(filterOptions('name'))]}
          disablePortal
          onChange={LocatePolygon}
          renderInput={(params) => <TextField {...params} size="small" label="name" />}
  />
  


<Typography variant='subtitle2'>By Date Interred or Date of Death</Typography>
    <TextField name='type' value={range.type} onChange={handleChange}
    select fullWidth variant="outlined" size="small" label="Select Type">

        <MenuItem value='date_interred'>Date Interred</MenuItem>
        <MenuItem value='date_of_death'>Date of Death</MenuItem>
        <MenuItem value='date_reserved'>Date Reserved</MenuItem>


    </TextField>

    <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Starting Date"
                    name='install_date'
                    value={range.start}
                    onChange={val=>handleChange(val,'start')}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                    label="Ending Date"
                    name='disposed_date'
                    value={range.end}
                    onChange={val=>handleChange(val,'end')}
                    renderInput={(params) => <TextField {...params} />}
                />
    </LocalizationProvider>
   <Button variant='outlined' onClick={highlightPlots}>Search</Button>
   
</Stack>
)
}


