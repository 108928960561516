import React, {useState, useContext, useEffect, useMemo} from 'react'
import { Autocomplete, Alert, TextField, Typography, Grid, MenuItem, Button, Slider, Stack, IconButton} from "@mui/material"
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import RoomIcon from '@mui/icons-material/Room';
import {AppContext} from "../AM App"
import {useForm} from "./useForm"
import {baseURL} from 'Constants'

export default function Repair() {
 
  const {asset,components, markerGeom, setIcon}=useContext(AppContext);
  const [repairs, setRepairs]=useState([]);
  const [repairTypes, setRepairTypes] = useState([])
  const initRepair= useMemo(()=>({
    pk_repair:'New Repair',
    time: "",
    fk_asset: asset.pk_asset,
    fk_component: '',
    repair_type: "",
    condition_assessment: 0,
    repair_description: "",
    geom: ''
  }),[asset.pk_asset])
  
  const [alert, setAlert]=useState({
    message:"",
    severity:""
  })

  const [repair, handleChange] = useForm(initRepair)
 
  useEffect(()=>{
    if (!markerGeom) return
    handleChange(markerGeom,'geom')
  },[markerGeom])


  function handleSubmit() { 
    fetch (`${baseURL}/repair`,{
        method:'PUT',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(repair)
      })
      .then(response=>response.json())
      .then(result=>{
          // console.log(result)
          setAlert(result)
      })
      .catch(error=>{
        setAlert({message:error.message, severity:'error'})
      })
  }
  
  useEffect (()=>{
    if (asset.pk_asset!==''){
      fetch (`${baseURL}/repair/${asset.pk_asset}`)
      .then(response=>response.json())
      .then(result=>{
          // console.log(result)
          setRepairs(result)
      })
      .catch(error=>console.log(error.message))
    }
  },[asset.pk_asset])

  useEffect (()=>{fetch (`${baseURL}/repairTypes`)
  .then(response=>response.json())
  .then(result=>{
      // console.log('Repairs: ', result)
      setRepairTypes(result)
  })
  .catch(error=>console.log(error.message))
},[])



return (
<div>
 {/* <pre className='span'>{JSON.stringify(repair, null,2)}</pre> */}
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12}>
      <IconButton sx={{ml:2}}  size='small' color='secondary' onClick={()=>setIcon(true)}>
              Add/Replace Marker... <RoomIcon />
      </IconButton>
    </Grid>
    <Grid item xs={12}>
       <TextField name='pk_asset' value={asset.pk_asset} 
      variant="outlined" size="small" label="Asset Key" disabled />
    </Grid>

    <Grid item xs={12}>
      <TextField variant="outlined" size="small" 
      // label={repair.pk_repair===''?'Select Repair':`Repairs Selected ${repair.pk_repair}`}
        label='Select Repair'
        select
        fullWidth
        name='replace'
        value={repair}
        onChange={handleChange}
        SelectProps={{renderValue:selected=>selected.pk_repair}}
      >
      <MenuItem  value={initRepair}><em>New Repair</em></MenuItem>
      {repairs.map((repair,idx)=>(
        <MenuItem key={idx} value={repair}>
          {repair.pk_repair}
        </MenuItem>
      ))}
      </TextField>
    </Grid>
  
    <Grid item xs={6}>
      <TextField variant="outlined" size="small" label="Component"
        select
        fullWidth
        onChange={handleChange}
        name='fk_component'
        value={repair.fk_component}
      >
       <MenuItem value={repair.fk_component} disabled>Select Component</MenuItem>
      {components.map((component, idx)=>(
        <MenuItem key={idx} value={component.pk_component}>
        {`${component.pk_component}-${component.component_name}`}
        </MenuItem>
      ))}
      </TextField>
    </Grid>
    <Grid item xs={6}>
    <Autocomplete
          id="repair_type"
          freeSolo
          fullWidth
          options={repairTypes.sort()}
          value={repair.repair_type}
          disablePortal
          onInputChange={(event, newValue)=>handleChange(newValue, 'repair_type')}
          renderInput={(params) => <TextField {...params} size="small" label="Repair Type" />}
       />
     
    </Grid>
    <Grid item xs={12}>
      <TextField value={repair.repair_description} onChange={handleChange}
      variant="outlined" name='repair_description' size="small" label="Description" fullWidth multiline />
    </Grid>
    <Grid item xs={12}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography id="input-slider">Condition</Typography>
        <SentimentDissatisfiedIcon />
        <Slider
          valueLabelDisplay="auto"
          name="condition_assessment"
          marks
          step={1}
          label="Condition"
          margin="auto"
          defaultValue={3}
          min={1}
          max={5}
          value={repair.condition_assessment}
          onChange={handleChange}
        />
        <SentimentSatisfiedOutlinedIcon />
      </Stack>
    </Grid>
    <Button variant="contained" sx={{ mt: 3 }} onClick={handleSubmit}>
    Insert/Update
  </Button>
  <Grid item xs={12} >
  {alert.message!==""&&<Alert onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}
    </Grid>
</Grid>
</div>
    )
}

