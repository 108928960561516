import React from 'react'
import  {Typography, Button} from "@mui/material";
import {AiOutlineYoutube} from "react-icons/ai"
import "./Home.css"
export default function Home() {
    return (
         <div>
            <Typography variant='h6'>Welcome to the Mapping App!</Typography>
            <Typography variant='body2'>The YouTube link below is an introduction on how to use the app and the
            accordian on the right allows you to select different layers. <br/><br/>
            The parcel layer will show you
            parcels with legal, dimensions, property taxes and utilities. The asset layers give details of water
            and sewer lines. The cemetery shows the plots with burial details.<br/><br/> 
            The search icon
            allows you to find any property you're looking for using address, pid etc. The search has an autocomplete feature that
            that shows you suggestions as you type.
            <br/>
            Enjoy!
             </Typography>
            <Button href="https://www.youtube.com/watch?v=h1aOa4UPMIM" target="_blank"><AiOutlineYoutube className="icon"/>Intro Video</Button>
            
        </div>
    )
} 
