import React, {useContext, useState, useEffect} from 'react'
import {TextField,  Grid, MenuItem, Button, LinearProgress, Typography, Alert,FormGroup, FormControlLabel, Checkbox} from "@mui/material"
import {useForm} from "./useForm"
import {AppContext} from "../AM App"
import {S3List} from './MakeTable'
import {MdExpandLess, MdExpandMore} from 'react-icons/md'
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import {baseURL} from 'Constants'
import { pink } from '@mui/material/colors';

const axiosInstance= axios.create({
  baseURL
})

export default function Upload() {
  const {asset, components, loginInfo}=useContext(AppContext);
  const tables=['assets','components', 'repairs', 'inspections']
  const [upload, handleChange]=useForm({
    tbl:'assets',
    fk:asset.pk_asset,
    description:'',
    high_res:false,
    file:{}
  })

  const [repairs, setRepairs]=useState([]);
  const [inspections, setInspections]=useState([]);
  const [mediaTable, setMediaTable]= useState(null)
  const [displayMedia, setDisplayMedia]=useState(false);
  const [progress, setProgress]=useState(0)
  const [alert, setAlert]=useState({
    message:"",
    severity:""
  })


useEffect(()=>{ //fetching dropdowns when asset changes
  async function fetchAll(){
    let response, result;
    try{
    response=await fetch (`${baseURL}/repair/${asset.pk_asset}`)
    result=await response.json()
    setRepairs(result)
    response=await fetch (`${baseURL}/inspection/${asset.pk_asset}`)
    result=await response.json()
    setInspections(result)
    }catch (error) {
        console.error(error.message);
    }
  }
 fetchAll()
 handleChange('replaceAsset', {  
 tbl:'assets',
 fk:asset.pk_asset,
 description:'',
 high_res:false,
 file:{}
})

},[asset.pk_asset])


  let drops=[];
  let refKey=''
  // const [drops, setDrops] = useState([])
  switch (upload.tbl){
    case 'repairs':
      drops=repairs;
      refKey='pk_repair'
      break;
    case 'inspections':
      drops=inspections;
      refKey='pk_inspection'
      break;
    case 'components':
      drops=components;
      refKey='pk_component'
     break;
    default:
      drops=[asset];
      refKey='pk_asset'; 
  }

 //upload files

 //function here to do front-end compression
 async function handleImageUpload() {
  // const imageFile = event.target.files[0];
  const imageFile = upload.file;
  setAlert({message:`Shrinking file on browser: Original Size ${(imageFile.size / 1024 / 1024).toFixed(2)} MB`, severity:'success'})
  // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    setAlert({message:`Compressed Size ${(compressedFile.size / 1024 / 1024).toFixed(2)} MB`, severity:'success'})
    return compressedFile
  } catch (error) {
    console.log(error);
  }
}

  
 async function handleSubmit(evt) { 

  // console.log(upload)
    let formData= new FormData();
  
    Object.keys(upload).forEach(key=>formData.append(key, upload[key]))
    formData.append('image_dir',loginInfo.municipality) //ISSUE ON SETUP WITH IMAGE DIRECTORY
  if(upload.file.type.match(/^image/) && !upload.high_res){
    const file=await handleImageUpload() //replacing with compressed
    formData.set('file',file,file.name)
  }
   
    axiosInstance.post("/upload",formData,{
      headers:{
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total))
       }
    })
    .then (response=>{
      refreshMediaTable(upload.tbl, upload.fk)
      setAlert(response.data)
      setProgress(0)
    // console.log('Success:', response.data)
    })
    .catch(error=>{
      console.log('Error:', error)
      setAlert({message:error, severity:'error'})
    })
  }



//-------------------------------------

    // for (var i = 0; i<upload.files.length; i++) {
    //   formData.append('file',upload.files[i]);
    //  }

  //   fetch('${baseURL}/upload', {
  //     method: 'POST',
  //     body:formData
  //   })
  //   .then ((response)=>response.text())
  //   .then((result)=>{
  //     refreshMediaTable(upload.tbl, upload.fk)
  //     console.log('Success:', result)
  //   })
  //   .catch(error=>console.log('Error:', error))
  // }
//-----------------------------------------------



//selecting image list

useEffect(()=>{
  fetch(`${baseURL}/media/${upload.tbl}/${upload.fk}`)
  .then(response=>response.json())
  .then(result=>{
    setMediaTable(result)
  })
},[upload.tbl, upload.fk])




//delete files ----------------------------------------------

function deleteFile(key,pk){
 
  fetch(`${baseURL}/delete`,{
    method:'DELETE',
    headers:{'Content-Type':'application/json'},
    body:JSON.stringify({
      key:key,
      pk:pk
    })
  })
  .then(response=>response.text())
  .then(result=>{
    // console.log(result)
    refreshMediaTable(upload.tbl,upload.fk)
  })
}

 const refreshMediaTable=(tbl,fk)=>{
  fetch(`${baseURL}/media/${tbl}/${fk}`)
  .then(response=>response.json())
  .then(result=>{
    setMediaTable(result)
  })
 }

return (
   
<Grid container spacing={2} justifyContent="center">
  {/* <pre className='span'>{JSON.stringify(upload, null,2)}</pre> */}
  <Grid item xs={6}>
    <TextField variant="outlined" size="small" label="Attaching file to ?"
      select
      fullWidth
      name='tbl'
      value={upload.tbl}
      onChange={handleChange}
    >
    {tables.map((table, idx)=>(
      <MenuItem key={idx} value={table}>
        {table}
      </MenuItem>
    ))}
    </TextField>
  </Grid>
  <Grid item xs={6}>
    <TextField variant="outlined" size="small" label="Item to attach file to"
      select
      fullWidth
      name='fk'
      value={upload.fk}
      onChange={handleChange}
    >

    {drops.map((drop, idx)=>(
      <MenuItem key={idx} value={drop[refKey]}>
        {drop[refKey]}
      </MenuItem>
    ))}
    </TextField>
  </Grid>
        <Grid item xs={12}>
          <TextField variant="outlined" label="Description" fullWidth multiline 
            name='description'
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} justifyContent='center'>
            <Button variant="outlined" color='success' component='label'>
              <input name='file' type="file"  
              onChange={handleChange}/>
            </Button>
        </Grid>
        <Grid item xs={12} justifyContent='center'>
        <FormGroup className='span' row={true} sx={{margin:'auto'}}>
            <FormControlLabel  control={<Checkbox 
              name='high_res'
              checked={upload.high_res}
              onChange={handleChange}
              sx={{
                    color: pink[800],
                    '&.Mui-checked': {
                    color: pink[600],
                    },
                    }}
            />} label={<Typography variant="body2" color="textSecondary">High res images, do not compress</Typography>} />
           
    </FormGroup>
     </Grid> 
      <Button variant="contained" sx={{mt:2}} onClick={handleSubmit}>
        Insert/Update
      </Button>
      
    <Grid item xs={12} >
    <Typography sx={{mb:1}}>Upload Progress:</Typography>
    <LinearProgress variant="determinate" value={progress} />
    {alert.message!==""&&<Alert className='span stretch' onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}
    <br/>

    <hr/>

    <Button variant='outline'  color='secondary' size='small' 
          endIcon={displayMedia? <MdExpandLess/> : <MdExpandMore/>} 
          onClick={()=>setDisplayMedia(!displayMedia)}> Images & Media
    </Button>
    {displayMedia && mediaTable&&<S3List rows={mediaTable} deleteFile={deleteFile} />}
    </Grid>
 </Grid>


    )
}

