import React from 'react'
import {Paper, IconButton,Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

 export function S3List({rows, deleteFile}) {
  //  url=`/download/${row.s3path}`

    return (
        <TableContainer className='span' component={Paper}>
        <Table sx={{ minWidth: 100 }} size='small' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row,idx) => (
              <TableRow key={idx}>
                <TableCell>
                  
                  <a href={row.url}>{row.file_name}</a>
                  {/* <img src=`${baseURL}/download/424f6f736dfbf4666e50dec21a8ffb4b_Bulb.jpg` alt="Test"></img> */}

                </TableCell>
                <TableCell>{row.desc}</TableCell>
                <TableCell>
                {/* <Button variant="outlined" startIcon={<DeleteIcon />} size="small" color='error' onClick={()=>deleteFile(row.key)}> */}
                <IconButton color='error' size='small' onClick={()=>deleteFile(row.key,row.pk)}><DeleteIcon fontSize='small' /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
}

 export default function MakeTable({rows}){
  const headers=Object.keys(rows[0]) //select first object and get keys
  return(
    <TableContainer  component={Paper} >
    <Table size='small'>
      <TableHead>
      <TableRow>
      {headers.map((header,idx)=>(
          <TableCell key={idx} sx={{fontSize:12}} >{header}</TableCell>
      ))}
      </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, idx)=>(
          <TableRow key={idx}>
          {headers.map((header, index)=>(
          <TableCell key={index} sx={{fontSize:12}} >{row[header]}</TableCell>
          ))}
          </TableRow>
        ))}
      </TableBody>  
    </Table>
    </TableContainer>
  )
}
