import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useForm} from '../useForm'
import Register from "./Register"
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'SaaS @ '}
      <Link color="inherit" href="https://jugaadinnovation.ca/">
        Jugaad Innovation
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();


  export default function LoginForm({Login, error}){
    const [register, setRegister]=useState(false)
    const [credentials, handleChange]=useForm({
      email:"",
      password:""
    })
  //  console.log("render")
  
    function handleSubmit(e) {
      e.preventDefault();
      Login(credentials);
    }
  return (
<ThemeProvider theme={theme}>
{register ? (<Register setRegister={setRegister}/>):(
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          onChange={handleChange}
          error={error==='user does not exist'}
          helperText={error==='user does not exist' && error}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
          error={error==='Password does not match'}
          helperText={error==='Password does not match' && error}
        />
    
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Button
          onClick={()=>setRegister(true)}
          fullWidth
          variant="text"
          color="secondary"
          sx={{ mt: 2, mb: 0 }}
        >
          Register
        </Button>

      </Box>
    </Box>
    <Copyright sx={{ mt: 8, mb: 4 }} />
  </Container>)}
</ThemeProvider>
    
  );
}




