import React, { useState, useEffect, useMemo, createContext } from 'react';
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import {SideDrawer} from "./Components/SideDrawer/SideDrawer"
import {MeasureControl} from "../SharedComponents/MeasureControl"

import "./AM App.css"
import Overlay from "./Components/Overlay"
import Sidebar from './Components/Sidebar/Sidebar';
import L from 'leaflet';
import CustomModal from "./Components/CustomModal"
import 'leaflet.pm'
import 'leaflet.pm/dist/leaflet.pm.css'
import {baseURL} from 'Constants'
let tempLayer;
export const AppContext= createContext(null);

export default function  AMApp({loginInfo, logout}){
    const [sideDrawerShow, setSideDrawerShow] = useState(false);
    const [drawerContent, setDrawerContent]=useState(null);
    const [title, setTitle] = useState(null)
    const [modalTitle, setModalTitle]=useState(null)
    const [map, setMap] =useState(null);
    const [position, setPosition]=useState(null)
    const [report, setReport]=useState(null)
    const [components, setComponents]=useState([]);
    const contentLabel= useMemo(()=>({val:null}),[]);
    const [openModal, setOpenModal] = useState(false);
    const [table, setTable]=useState(null);
    const [createGeom, setCreateGeom]=useState(false);
    const [editGeom, setEditGeom]=useState(false);
    const [lyrEdit, setLyrEdit]=useState(null)
    const [icon, setIcon]=useState(false)
    const [asset, setAsset]=useState(null)
    const [folio, setFolio]=useState(null)
    const [cemeteryMapRef, setCemeteryMapRef]=useState(null)
    const [markerGeom, SetMarkerGeom]=useState(null)
    var attrib='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a><br>'
    attrib+='Powered by <a href="https://ltsa.ca/products-services/parcelmap-bc">ParcelMap BC</a>'

    const [json, setJson] =useState({
        Wastewater:[],
        Stormwater:[],
        Potablewater:[],
        'Parks, Recreation & Culture':[],
        'Buildings & Structures':[],
        Transportation:[],
        Fleet:[],
        Equipment:[],
        ITS:[],
       'Natural Assets':[],
        ParcelMap:[],
        Zoning:[],
        Cemetery:[]      
    });

    //fetch
    useEffect(function(){
        fetch (`${baseURL}/amsMap`)
        .then(response=>response.json())
        .then(result=>{
            let allGeoJson=result.features;
            // console.log(allGeoJson);
            setJson({
                Wastewater:allGeoJson.filter(feature=>feature.properties.asset_group==='Wastewater'),                
                Stormwater:allGeoJson.filter(feature=>feature.properties.asset_group==='Stormwater'),
                Potablewater:allGeoJson.filter(feature=>feature.properties.asset_group==='Potablewater'),
                'Parks, Recreation & Culture':allGeoJson.filter(feature=>feature.properties.asset_group==='Parks, Recreation & Culture'),
                'Buildings & Structures':allGeoJson.filter(feature=>feature.properties.asset_group==='Buildings & Structures'),
                Land:allGeoJson.filter(feature=>feature.properties.asset_group==='Land'),
                Fleet:allGeoJson.filter(feature=>feature.properties.asset_group==='Fleet'),
                Roads:allGeoJson.filter(feature=>feature.properties.asset_subgroup==='Roads'),
                Equipment:allGeoJson.filter(feature=>feature.properties.asset_group==='Equipment'),
                ITS:allGeoJson.filter(feature=>feature.properties.asset_group==='ITS'),

                // Sidewalk:allGeoJson.filter(feature=>feature.properties.asset_subgroup==='Sidewalk'),
                ParcelMap:allGeoJson.filter(feature=>feature.properties.asset_group==='pmbc'),
                utilityLyr:allGeoJson.filter(feature=>feature.properties.asset_group==='Utility'),
                Zoning:allGeoJson.filter(feature=>feature.properties.asset_group==='Zoning'),
                Cemetery:allGeoJson.filter(feature=>feature.properties.asset_group==='cemetery_map'),

            })
        })
        .catch(error=>console.log(error.message))
        
    },[])

    const drawerClickHandler=(newTitle)=>{
        //  console.log(`newTitle: ${newTitle}, title: ${title}, sideDrawerShow: ${sideDrawerShow}`)
        if (!sideDrawerShow) setSideDrawerShow(true)
        if (newTitle!==title && sideDrawerShow) return
        if (newTitle===title && sideDrawerShow) setSideDrawerShow(false)
    }
   

    //useEffect to limit renders
    // useEffect(()=>{
    //     if(!map) return
    //     // console.log(title)
    //     if(asset&&sideDrawerShow&&(title==='Repair' || title==="Inspect/Maintain")){
    //         map.pm.enableDraw('Marker')
    //         map.on('pm:create', function(e){
    //             SetMarkerGeom(e.layer.toGeoJSON().geometry)
    //             map.pm.disableDraw('Marker')
    //             // map.removeLayer(e.layer)
    //         })
    //     }else if ((title!=='Repair' || title==="Inspect/Maintain"||!sideDrawerShow)){
    //         map.pm.disableDraw('Marker')
    //     }
    // },[asset, title,sideDrawerShow])

    // useEffect(()=>{
    //     if(!map) return
    //     if(asset&&sideDrawerShow&&icon&&(title==='Repair' || title==="Inspect/Maintain")){
    //         map.pm.enableDraw('Marker')
    //         map.on('pm:create', function(e){
    //             SetMarkerGeom(e.layer.toGeoJSON().geometry)
    //             map.pm.disableDraw('Marker')
    //             // map.removeLayer(e.layer)
    //         })
    //     }else if ((title!=='Repair' || title==="Inspect/Maintain"||!sideDrawerShow)){
    //         map.pm.disableDraw('Marker')
    //         setIcon(false)
    //     }
    // },[icon,title,sideDrawerShow])

    useEffect(()=>{
        if(!map) return
        if(icon){
            map.pm.enableDraw('Marker')
            map.on('pm:create', function(e){
                SetMarkerGeom(e.layer.toGeoJSON().geometry)
                map.pm.disableDraw('Marker')
                setIcon(false)
                // map.removeLayer(e.layer)
            })}
        
    },[icon])

    const providerValues= useMemo(()=>({
        loginInfo,sideDrawerShow, setSideDrawerShow, drawerContent, setDrawerContent,cemeteryMapRef,setCemeteryMapRef,
        title, setTitle, pmbc:json.ParcelMap, cemetery:json.Cemetery, setPosition, setIcon,
        contentLabel, setReport, components, setComponents, setOpenModal, setModalTitle, modalTitle, setTable, table, openModal,
        setCreateGeom, setEditGeom, editGeom, setLyrEdit, lyrEdit, asset, setAsset, folio, setFolio, markerGeom
        }), [sideDrawerShow, drawerContent, title, json.ParcelMap, json.Cemetery, components, modalTitle, cemeteryMapRef,
              contentLabel, openModal, table, lyrEdit, asset, folio,loginInfo, markerGeom, editGeom ])
              
    useEffect(function(){
        if (position) {
            // console.log(title)
            if(tempLayer) map.removeLayer(tempLayer);
            if (title==='Search Assets'){ 
                tempLayer=L.geoJSON(position, {
                    pointToLayer:function(feature,latlng){
                        return L.circleMarker(latlng,
                        {radius:5, color:"#002060",fillOpacity:.5})},
                    onEachFeature:function(feature, layer){
                        let att=feature.properties;
                        // console.log("attribures:",att)
                        layer.bindPopup("<b>Asset Key:</b>"+att.pk_asset+" <b> Asset Name:</b>"+att.asset_name+"<b> AssetID:</b>"+att.asset_id+ "<b> fs_Class:</b>"+att.fs_class+"<b> Condition:</b>"+att.asset_condition+"<b> Consequence:</b>"+att.consequence+"<b> Risk:</b>"+att.risk)
                        layer.on('click', function(){ //setting edit layer
                            const geom=layer.toGeoJSON().geometry
                            att.geom=geom;
                            setAsset(att);
                            setLyrEdit(layer)
                            //setting components
                            fetch (`${baseURL}/component/${att.pk_asset}`)
                            .then(response=>response.json())
                            .then(result=>{
                                setComponents(result)
                                })
                            .catch(error=>console.log(error.message))
                        });
                    }
                })
                map.fitBounds(tempLayer.getBounds(), {maxZoom:21})
                tempLayer.addTo(map).bringToBack();
            }else {
                tempLayer=L.geoJSON(position);
                map.fitBounds(tempLayer.getBounds(),{maxZoom:21})
                tempLayer.addTo(map).bringToBack();
                // map.flyTo(center, map.getZoom())
            }
        }
    },[position])
  
    useEffect(()=>{
        if(tempLayer) map.removeLayer(tempLayer);

        if (report && report.features.length>0){
            // console.log(report.features)
            tempLayer=L.geoJSON(report, {
                style:riskStyle,
                // pointToLayer:function(feature,latlng){
                //     return L.circleMarker(latlng,
                //     {radius:5, color:"#A9F6B2",fillOpacity:.5})},
                onEachFeature:function(feature, layer){
                    let att=feature.properties;
                    // console.log(att)
                    if(att.pk_repair){
                        layer.bindPopup(`<b>Repair Key:</b> ${att.pk_repair}<br><b>Repair Type:</b> ${att.repair_type}<br>
                        <b>Repair Description:</b> ${att.repair_description}<br>
                        <b>Asset Key:</b>${att.pk_asset}<br><b>Asset Name:</b>${att.asset_name}<b>`)
                    }else if(att.pk_inspection){
                        layer.bindPopup(`<b>Inspection Key:</b> ${att.pk_inspection}<br><b>Inspection Type:</b> ${att.inspection_type}<br>
                        <b>Inspection Description:</b> ${att.inspection_description}<br>
                        <b>Asset Key:</b> ${att.pk_asset}<br><b>Asset Name:</b> ${att.asset_name}<b>`)

                    }else{
                        layer.bindPopup("<b>Asset Key:</b>"+att.pk_asset+" <b>Asset Name:</b>"+att.asset_name+
                        "<b> Condition:</b>"+att.asset_condition+"<b> Consequence:</b>"+att.consequence+"<b> Risk:</b>"+att.risk)
                    }
                }
            })
            map.fitBounds(tempLayer.getBounds())
            tempLayer.addTo(map)
        }
    },[report])

    useEffect(()=>{
        if(!map) return
        if (createGeom){
          map.pm.addControls({
            position:'topright',
            editMode:false,
            dragMode:false,
            cutPolygon:false,
            removalMode:false,
            drawCircle:false,
            drawCircleMarker:false
          });
          map.on('pm:create', function(e){
            setAsset({
                ...asset,
                pk_asset:'New Asset',
                geom:e.layer.toGeoJSON().geometry
            })
            map.pm.disableDraw()
  
          })

        }else if (editGeom){
            lyrEdit.pm.enable();
            lyrEdit.on('pm:edit', e => {
                setAsset({
                    ...asset,
                    geom:lyrEdit.toGeoJSON().geometry
                })
              });
        }
        else if (lyrEdit && !editGeom){
            lyrEdit.pm.disable();
        }
      },[map, createGeom, editGeom, lyrEdit])
  
    map && map.on('overlayadd', (e)=>{
        if(e.name==='Cemetery'){
            map.fitBounds(e.layer.getBounds())
        }
    })



    return(
<div className="grid-container">
  <AppContext.Provider value={providerValues} >
    <Sidebar drawerClickHandler={drawerClickHandler} logout={logout} />
    <SideDrawer  />

    {/* <pre>{JSON.stringify(json, null,2)}</pre>     */}
    
    <MapContainer center={loginInfo.map_center} zoom={15} maxZoom={21} zoomControl={false}
            
        whenCreated={map=>setMap(map)}
        
        >
     <MeasureControl />   
     {/* {position && <GeoJSON data={position} style={pathOptions} /> }  */}
   
        <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Open Street Map">
                <TileLayer
                attribution={attrib}
                url="https://{s}.tile.openstreetMap.org/{z}/{x}/{y}.png"
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="MapBox Ortho">
                <TileLayer
                url='https://api.Mapbox.com/styles/v1/ysbhalla/ckoqe52rx39j517p5scfqqbiy/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieXNiaGFsbGEiLCJhIjoiY2tvcWN2ZjF0MHB1ZTJ3cnhiN29sZnRrNCJ9.2Gt7VerhEbx1dmhxyurcOQ'
                />            
            </LayersControl.BaseLayer>
            {json.Wastewater.length>0 && Object.keys(json).map((keyName, index)=>
            keyName!=='utilityLyr' &&
                <Overlay
                    key={index}
                    lyrName={keyName}
                    lyr={json[keyName]}
                    utilityLyr={keyName==='ParcelMap'? json.utilityLyr:null}
                />
            )}
        </LayersControl>
    </MapContainer>
    <CustomModal />
  </AppContext.Provider>
</div>
 );
}
      
function riskStyle(json){
    var styles;
    var risk=json.properties.risk;
    styles={color:getGradient(risk), weight:4}
    return styles;      
  }
    
function getGradient(d){
    var colorArray=["#2bd500","#ffff00","#d52b00"];
    return  d<=9 ? colorArray[0]:
            d>9 && d<=14 ? colorArray[1]:
            d>14 && d<=25 ? colorArray[2]:
            'black';
}


