import React, {useState, useEffect, useContext, useReducer} from 'react'
import BinField from "./BinField"
import {AppContext} from "../../AM App"
import Button from "@mui/material/Button"
import SaveIcon from '@mui/icons-material/Save';
import Alert from "@mui/material/Alert"
import { Typography} from "@mui/material"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import "./Bin.css";
import {baseURL} from 'Constants'

export const ACTIONS={
  SET_SERVICES:'set-services',
  ADD_SERVICES:'add-services',
  DELETE_SERVICES:'delete-services',
  UPDATE_SERVICES:'update-services'
}
const newItem={
  "cat": "02",
  "serv": "001",
  "units": 1,
  "bins": null,
  "cat_desc": "WATER",
  "serv_desc": " WATER - SINGLE FAMILY"
}


let arrayDelete=[]

function reducer(array, actions){
  switch(actions.type){
    case ACTIONS.SET_SERVICES:
      return actions.result
    case ACTIONS.ADD_SERVICES:
      return [...array, {...newItem, id:'new'+array.length, folio:actions.folio}]
    case ACTIONS.DELETE_SERVICES:
      if (!isNaN(actions.id)){arrayDelete.push(actions.id)}
      return array.filter(obj=>obj.id!==actions.id)
    case ACTIONS.UPDATE_SERVICES:
      return array.map(obj=>{
        if (obj.id===actions.payload.id){
          return {...actions.payload}
        }
        return obj
      })
    default:
      return array
  }
}

export default function Bins() {
    const {folio:map_folio}=useContext(AppContext);

    // const [arr, setArr]=useState(null)

    const [types, setTypes]=useState(null)

    const [array, dispatch]=useReducer(reducer, [])

    const [alert, setAlert]=useState({
        message:"",
        severity:""
      })

    useEffect(() => {
      fetch (`${baseURL}/utilityTypes`)
        .then(response=>response.json())
        .then(result=>{
            setTypes(result)
            // console.log(result)
        })
        
      
    }, [])

    const refreshUtilities=()=>{
      fetch (`${baseURL}/utility/${map_folio}`)
      .then(response=>response.json())
      .then(result=>{
          console.log(result)
          dispatch({type:ACTIONS.SET_SERVICES, result:result})
      })
    }

    useEffect(()=>{
      refreshUtilities()
      }, [map_folio])

      // const changeField = (idx, name, value)=>{
      //    setArr(curr=>{
      //         curr[idx][name]=value
      //          return curr
      //     })
      //   setCount(count=>count+1)   //to refresh
      // }


      //THIS MUST CHANGE
    const handleSubmit=()=>{

      // console.log(arrayDelete)

      fetch (`${baseURL}/utility`,
          {
            method:'PUT',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(array)
          })
          .then(response=>response.json())
          .then(result=>{
              setAlert(result)
          })
          .catch(error=>{
            console.log(error)
            setAlert({message:'fetch error', severity:'error'})
          })
      arrayDelete.length!==0 && fetch (`${baseURL}/utility`,
      {
        method:'DELETE',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(arrayDelete)
      })
      .then(response=>response.json())
      .then(result=>{
          setAlert(result)
          arrayDelete=[]
      })
      .catch(error=>{
        console.log(error)
        setAlert({message:'fetch error', severity:'error'})
      })
      // refreshUtilities()

    }


    return (
    <div>
        {/* <pre>{JSON.stringify(array, null,2)}</pre>  */}
        {/* <pre className='span'>{JSON.stringify(types, null,2)}</pre>  */}
        <Typography  variant="subtitle1" className="span stretch">
      Folio: {array.length>0&& array[0].folio}
     </Typography>
             {array && types && array.map((obj, idx)=>             
                <BinField key={idx} idx={idx} array={array} obj={obj} types={types} dispatch={dispatch}
                 /> 
             )}
            <div>
            <Button  variant="outlined" color="primary" size='small' startIcon={<AddCircleOutlineIcon/>} sx={{mt:1}}
            onClick={()=>dispatch({type:ACTIONS.ADD_SERVICES, folio:map_folio})}>Add Service</Button>
            </div>
          
              <Button className='span stretch' variant="contained" color="primary" size='small' startIcon={<SaveIcon/>} sx={{mt:1}}
            onClick={handleSubmit}>Save Changes</Button>

        {alert.message!==""&&
        <Alert
        onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}

    </div>


    
    )
}
