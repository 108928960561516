import React, {useState, useContext, useEffect, useMemo} from "react";
import {Autocomplete, Alert, Button, TextField, MenuItem, Slider, Typography, Stack} from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import "./Asset.css";
import {MdExpandLess, MdExpandMore} from 'react-icons/md'
import Financials from "./Financials";
import {AppContext} from "../../AM App"
import {useForm} from "../useForm"
import {baseURL} from 'Constants'




export default function Component() {
  const [financial, setFinancial]= useState(false);
  const {asset,components}=useContext(AppContext);
  const date= new Date()
  const initComponent=useMemo(()=>({
    amortized_over: '',
    component_condition: 0,
    component_mod: "",
    component_name: "",
    component_notes: '',
    component_type: "",
    cost: "",
    crc: "",
    diam: '',
    disposed_date:"",
    fk_asset: asset.pk_asset,
    funding_source1: "",
    funding_source2: "",
    install_date: date,
    material: '',
    pk_component: 'New Component',
    pod: '',
    revised_useful_life: '',
    useful_life: ''
    }), [asset.pk_asset])

  // const [components, setComponents] = useState([]);
  // setComponents(comp.list) 

  const [alert, setAlert]=useState({
    message:"",
    severity:""
  })
    
  const [component, handleChange]=useForm(initComponent)
  const [componentTypes, setComponentTypes]=useState([]);

  useEffect (()=>{fetch (`${baseURL}/componentTypes`)
      .then(response=>response.json())
      .then(result=>{
          // console.log(result)
          setComponentTypes(result)
      })
      .catch(error=>console.log(error.message))
    },[])
    
  const handleSubmit = () => {

    for (const [key, value] of Object.entries(component)){
      if (value==="") delete component[key]
    }


    // console.log(component);

    fetch (`${baseURL}/component`,
      {
        method:'PUT',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(component)
      })
      .then(response=>response.json())
      .then(result=>{
          setAlert(result)
      })
      .catch(error=>console.log(error.message))
  };

  return (
    <div className='asset'>
     {/* <pre className='span'>{JSON.stringify(component, null,2)}</pre> */}
     <TextField variant="outlined" size="small" label="Select Component"
          select
          name='replace'
          fullWidth
          value={component}
          onChange={handleChange}
          SelectProps={{renderValue:selected=>selected.pk_component}}
        >
        <MenuItem value={initComponent}>{`#${initComponent.pk_component}-${initComponent.component_name}`}</MenuItem>
        {components.map((component, idx)=>(
          <MenuItem key={idx} value={component}>
            {`#${component.pk_component}-${component.component_name}`}
          </MenuItem>
        ))}
    </TextField>
    <TextField name='component_name' onChange={handleChange} value={component.component_name} variant="outlined" size="small" label="Component Name" />
    <Autocomplete
          id='component_type'
          name="component_type"
          freeSolo
          fullWidth
          options={componentTypes.sort()}
          value={component.component_type}
          onInputChange={(event, newValue)=>handleChange(newValue, 'component_type')}
          renderInput={(params) => <TextField {...params} size="small" label="Component Type" />}
       />
    <TextField name='diam' value={component.diam} onChange={handleChange} type='number' variant="outlined" size="small" label="Diam/Width" />
    <TextField name='material' value={component.material} onChange={handleChange} variant="outlined" size="small" label="Material" />
    <TextField name='component_notes' value={component.component_notes} onChange={handleChange} className='span stretch' variant="outlined"  multiline label="Component Notes" />
    <Stack className='span stretch' spacing={1} direction="row" alignItems="center">
          <Typography id="componentCondition">Component Condition</Typography>
          <SentimentDissatisfiedIcon />
          <Slider
              valueLabelDisplay="auto"
              name="component_condition"
              marks
              step={1}
              label="componentCondition"
              margin="auto"
              defaultValue={3}
              min={1}
              max={5}
              value={component.component_condition===''?0:component.component_condition}
              onChange={handleChange}
          />
          <SentimentSatisfiedOutlinedIcon />
    </Stack>
      <Button variant='outline' className='span stretch'  color='secondary' size='small' 
      endIcon={financial? <MdExpandLess/> : <MdExpandMore/>} onClick={()=>setFinancial(!financial)}> Financial Information</Button>
      {financial && <Financials handleChange={handleChange} component={component}  />}
       <Button className='span stretch'  variant='contained' onClick={handleSubmit}> Submit</Button>
       {alert.message!==""&&<Alert onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}
  </div>
 
  )
}
