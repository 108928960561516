import { useState } from 'react'


export const useForm = (initialValues)=>{
    const [values, setValues]=useState(initialValues)
    
    return [
        values, (e,x)=>{
            if (e.target){
                // console.log(e.target.value)
                if(e.target.name==='replace'){
                    const {value:obj}=e.target
                    if (obj.asset_group=='cemetery_map'){
                        obj.date_interred=new Date(obj.date_interred)
                        obj.date_of_death=new Date(obj.date_of_death)
                        obj.date_reserved=new Date(obj.date_reserved)
                    }
                 
                    Object.keys(obj).forEach(key=>{ //replacing nulls with ''
                    if (obj[key]===null) obj[key]=''
                    })  
                    setValues(obj)
                }else if (e.target.type==='checkbox'){
                    setValues({
                        ...values,
                        [e.target.name]:x
                    })
                }else if (e.target.type==='file'){
                    setValues({
                        ...values,
                        [e.target.name]:e.target.files[0]
                    })
                }else {
                   setValues({
                       ...values,
                       [e.target.name]:e.target.value
                   }) 
                }
            } else if (e==='replaceAsset'){
                // Object.keys(x).forEach(key=>{
                //     if (x[key]===null){
                //         if (key==='verified') {x[key]=false}else{
                //             x[key]=''
                //         }
                //     }
                // })
                setValues(x)
            }else { //autocomplete, datepicker
                // console.log({...values, [x]:e})
                setValues({
                    ...values,
                    [x]:e
                })
            }
        }
    ]
}

