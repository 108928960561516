import React, {useState, useContext} from "react";
import {Button,  ButtonGroup, IconButton} from "@mui/material";
import Asset from "../Edit/Asset"
import Component from "../Edit/Component"
import {MdCreate,MdAddCircleOutline} from 'react-icons/md'
import {AppContext} from "../../AM App"


export default function EditContainer(params) {
   const {setEditGeom, setCreateGeom, editGeom, loginInfo }=useContext(AppContext); 
   const [show, setShow]=useState(null)
    const showAsset=()=>{
        setShow(<Asset />)
    }
    const showComponent=()=>{
        setShow(<Component />)
    }

    return (
        <div>
            <IconButton sx={{ml:2}}  size='small' color='secondary' onClick={()=>setCreateGeom(true)}>
            Add Shape.. <MdAddCircleOutline />
            </IconButton>
            <IconButton  sx={{ml:2}}  size='small' color='secondary' 
            onClick={()=>setEditGeom(!editGeom)}>{editGeom?'Stop Edit':'Edit Shapes..'}<MdCreate /> 
            </IconButton>
            <ButtonGroup sx={{mb:2}}  variant="outlined" fullWidth>
                <Button  onClick={showAsset}>Edit Asset</Button>
               {loginInfo.role==="Manager" && <Button  onClick={showComponent}>Edit Component</Button>}
            </ButtonGroup>
         
        {show}
        </div>
      );
    
}




// onClick={()=>setEditGeom(true)}>{editGeom?'Stop Edit':'Edit Shapes..'}<MdCreate /> 
