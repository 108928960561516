import React, {useContext,useEffect, useState} from "react";
import { Autocomplete, Alert, Button, FormGroup, FormControlLabel, Checkbox,TextField, MenuItem, Slider, Typography, Stack} from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import { pink } from '@mui/material/colors';
import "./Asset.css";
import {AppContext} from "../../AM App"
import {useForm} from "../useForm"
import {baseURL} from 'Constants'


export default function Asset() {
  const {asset:map_asset, loginInfo}=useContext(AppContext);

  //removing material and diam pulled from components in order to have colour coded maps
  delete map_asset.material
  delete map_asset.diam 
  //app context is slow, so assigning to local variable
  const [asset, handleAsset] =useForm(map_asset) 
  const [alert, setAlert]=useState({
    message:"",
    severity:""
  })
 
  const [drops, setDrops]= useState({
    asset_group:['Land'],
    asset_subgroup:['Natural Assets'],
    asset_type:['Land'],
    fs_class:['Natural Assets'],
    tourism_grouping:[''],
    asset_subtype:[''],
    })

  useEffect(()=>{
    handleAsset('replaceAsset',map_asset)
  },[map_asset])
  
  useEffect(()=>{
    fetch (`${baseURL}/amsMap/dimensions/`)
    .then(response=>response.json())
    .then(result=>{
        // console.log(result)
        setDrops(result)
    })
  }, [map_asset])
  

  const handleSubmit = () => {
    for (const [key, value] of Object.entries(asset)){
      if (value==="") delete asset[key]
    }
    // console.log(asset);
    fetch (`${baseURL}/asset`,
    {
      method:'PUT',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify(asset)
    })
    .then(response=>response.json())
    .then(result=>{
        // console.log(result)
        setAlert(result)
    })
    .catch(error=>{
      console.log(error.message)
      setAlert({message:'fetch error', severity:'error'})
    })
 
  };
  
  return (
    <div className="asset">
    {/* <pre className='span'>{JSON.stringify(asset, null,2)}</pre> */}
      <TextField name='pk_asset' value={asset.pk_asset} onChange={handleAsset} 
      variant="outlined" size="small" label="Asset Key" disabled />
      <TextField name='asset_id' value={asset.asset_id} onChange={handleAsset} 
      variant="outlined" size="small" label="Asset ID-Optional" />
      <TextField name='asset_name' value={asset.asset_name} onChange={handleAsset}
      variant="outlined" size="small" label="Asset Name" />
      <TextField name='asset_notes' value={asset.asset_notes===null?'':asset.asset_notes} onChange={handleAsset} className='span stretch' variant="outlined" size="small" multiline label="Asset Notes" />

     { loginInfo.role==="Manager" && (<>
     <TextField name='asset_group' value={asset.asset_group} onChange={handleAsset} 
        select fullWidth variant="outlined" size="small" label="Asset Group">
        {drops.asset_group.map((fs, idx)=>(
          <MenuItem key={idx} value={fs}>
            {fs}
          </MenuItem>
        ))}
        </TextField>
        <TextField name='asset_subgroup' value={asset.asset_subgroup} onChange={handleAsset}
         select fullWidth variant="outlined" size="small" label="Asset SubGroup">
         {drops.asset_subgroup.map((fs, idx)=>(
              <MenuItem key={idx} value={fs}>
                {fs}
              </MenuItem>
          ))}   
         </TextField>
         <Autocomplete
            id="asset_type"
            value={asset.asset_type}
            freeSolo
            fullWidth
            options={drops.asset_type.sort((a,b)=>a-b)}
            disablePortal
            onInputChange={(event, newValue)=>handleAsset(newValue, 'asset_type')}
            renderInput={(params) => <TextField {...params} size="small" label="Asset Type" />}
         />
  
        <TextField name='fs_class' variant="outlined" size="small" label="Financial Class"
              value={asset.fs_class}
              onChange={handleAsset}
              select
              fullWidth
            >
           {/* <MenuItem value=''></MenuItem> */}
            {drops.fs_class.map((fs, idx)=>(
              <MenuItem key={idx} value={fs}>
                {fs}
              </MenuItem>
            ))}
         </TextField>
         <Autocomplete
            id="tourism_grouping"
            value={asset.tourism_grouping}
            freeSolo
            fullWidth
            options={drops.tourism_grouping.sort((a,b)=>a-b)}
            disablePortal
            onInputChange={(event, newValue)=>handleAsset(newValue, 'tourism_grouping')}
            renderInput={(params) => <TextField {...params} size="small" label="Tourism Group" />}
         />
          <Autocomplete
            id="asset_subtype"
            value={asset.asset_subtype}
            freeSolo
            fullWidth
            options={drops.asset_subtype.sort((a,b)=>a-b)}
            disablePortal
            onInputChange={(event, newValue)=>handleAsset(newValue, 'asset_subtype')}
            renderInput={(params) => <TextField {...params} size="small" label="Asset SubType" />}
          />    
         
         <Stack className='span stretch' spacing={1} direction="row" alignItems="center">
             <Typography id="input-slider">Condition</Typography>
              <SentimentDissatisfiedIcon />
              <Slider
                  valueLabelDisplay="auto"
                  name="asset_condition"
                  marks
                  step={1}
                  label="Condition"
                  margin="auto"
                  defaultValue={3}
                  min={1}
                  max={5}
                  value={asset.asset_condition}
                  onChange={handleAsset}
              />
              <SentimentSatisfiedOutlinedIcon />
          </Stack>
          <Stack className='span stretch' spacing={1} direction="row" alignItems="center">
              <Typography id="input-slider">Consequence</Typography>
              <SentimentDissatisfiedIcon />
              <Slider
                  valueLabelDisplay="auto"
                  name="consequence"
                  marks
                  step={1}
                  label="Consequence"
                  margin="auto"
                  defaultValue={3}
                  min={1}
                  max={5}
                  value={asset.consequence}
                  onChange={handleAsset}
              />
              <SentimentSatisfiedOutlinedIcon />
          </Stack>
          <Stack className='span stretch' spacing={1} direction="row" alignItems="center">
              <Typography id="input-slider">LOS</Typography>
              <SentimentDissatisfiedIcon />
              <Slider
                  valueLabelDisplay="auto"
                  name="los"
                  marks
                  step={1}
                  label="LOS"
                  margin="auto"
                  defaultValue={3}
                  min={1}
                  max={5}
                  value={asset.los}
                  onChange={handleAsset}
              />
              <SentimentSatisfiedOutlinedIcon />
          </Stack>
          <Stack className='span stretch' spacing={1} direction="row" alignItems="center">
              <Typography id="input-slider">Function</Typography>
              <SentimentDissatisfiedIcon />
              <Slider
                  valueLabelDisplay="auto"
                  name="function"
                  marks
                  step={1}
                  label="Function"
                  margin="auto"
                  defaultValue={3}
                  min={1}
                  max={5}
                  value={asset.function}
                  onChange={handleAsset}
              />
              <SentimentSatisfiedOutlinedIcon />
          </Stack>
          <Stack className='span stretch' spacing={1} direction="row" alignItems="center">
              <Typography id="input-slider">Capacity</Typography>
              <SentimentDissatisfiedIcon />
              <Slider
                  valueLabelDisplay="auto"
                  name="capacity_utilization"
                  marks
                  step={1}
                  label="Capacity"
                  margin="auto"
                  defaultValue={3}
                  min={1}
                  max={5}
                  value={asset.capacity_utilization}
                  onChange={handleAsset}
              />
              <SentimentSatisfiedOutlinedIcon />
          </Stack>
          <FormGroup className='span' row={true} sx={{margin:'auto'}}>
              <FormControlLabel control={<Checkbox 
                name='verified'
                checked={asset.verified}
                onChange={handleAsset} 
              />} label="Verified" />
              <FormControlLabel control={
                  <Checkbox
                  name='disposed'
                  onChange={handleAsset} 
                  sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                      color: pink[600],
                      },
                      }}
                  />}
              label="Disposed" />
          </FormGroup>
        </>)}
      <Button className='span stretch'  variant='contained' onClick={handleSubmit}> Submit</Button>
      {alert.message!==""&&<Alert className='span stretch' onClose={() => setAlert({message:"", severity:"success"})} severity={alert.severity}>{alert.message}</Alert>}

     
    </div>
  );
}
