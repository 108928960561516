import React from 'react'
import {Card, CardContent, CardMedia, Typography} from '@mui/material'
export default function BlankBin() {
    return (
        <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="pickme squir"
        height="140"
        image="/static/pickme.jpeg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Select a property first
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Pick me squirrels  are a widespread group of squamate squiggles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      </Card>
    )
}